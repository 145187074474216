+<template>
  <el-popover
    placement="top-start"
    width="250"
    trigger="hover"
  >
    <div v-loading="isBusy">
      <div
        class="pb-0 pt-2"
        style="font-weight: 600; font-size: 0.9em; color: rgba(0, 0, 0, 0.3)"
      >
        Module(s)
      </div>

      <div v-if="event.data.course_units_with_batch.length > 3">
        <div style="font-weight: 500; font-size: 0.9em">
          {{ event.data.course_units_with_batch.length }} Modules
        </div>
      </div>

      <div v-else>
        <div
          v-for="(courseUnit, index) in event.data.course_units_with_batch"
          :key="index"
          style="font-weight: 500; font-size: 0.9em"
        >
          {{ courseUnit }}
        </div>
      </div>

      <div
        class="pb-0 pt-2"
        style="font-weight: 600; font-size: 0.9em; color: rgba(0, 0, 0, 0.3)"
      >
        Topic
      </div>
      <div style="font-weight: 500; font-size: 0.9em">
        {{ event.data.title == "N/A" ? "Topic N/A" : event.data.title }}
      </div>
      <div
        class="pb-0 pt-2"
        style="font-weight: 600; font-size: 0.9em; color: rgba(0, 0, 0, 0.3)"
      >
        Lecturer
      </div>
      <div style="font-weight: 500; font-size: 0.9em">
        {{ event.data.lecturer.first_name }}
        {{ event.data.lecturer.last_name }}
      </div>
      <div
        class="pb-0 pt-2"
        style="font-weight: 600; font-size: 0.9em; color: rgba(0, 0, 0, 0.3)"
      >
        Time
      </div>
      <div style="font-weight: 500; font-size: 0.9em">
        {{ formattedDate(event.data.date) }}
        <div style="font-size: 0.8em; color: rgba(0, 0, 0, 0.45)">
          {{ `${event.data.start_time} - ${event.data.end_time}` }}
        </div>
      </div>
      <div
        v-if="event.data.class_status == 'Attended'"
        class="pb-0 pt-2 text-center"
        style="
          font-weight: 600;
          font-size: 0.8em;
          color: rgba(61, 206, 60, 0.8);
        "
      >
        <i>You attended this Lecture</i>
      </div>
      <div
        v-else-if="event.data.class_status == 'Missed'"
        class="pb-0 pt-2 text-center"
        style="
          font-weight: 600;
          font-size: 0.8em;
          color: rgba(255, 0, 0, 0.616);
        "
      >
        <i>You missed this Lecture</i>
      </div>
    </div>
    <div
      slot="reference"
      class="text-left w-100 event-item"
      style="
        font-size: 0.75em;
        background-color: #d7d7f3;
        border-radius: 8px 8px 0px 8px;
        position: relative;
        border: 1px solid rgba(170 170 245, 0.5);
      "
      :style="{
        backgroundColor: event.type == 'Lecture' ? '#d7d7f3' : '#FFD6C4',
        height: `${fractionalLength}px`,

        top: `${fractionalTopMarging}px`,
      }"
    >
      <i
        v-if="event.data.class_status == 'Attended'"
        class="el-icon-check color-green"
        style="font-size: 20px; position: absolute; right: 10px; top: 10px"
      ></i>
      <i
        v-else-if="event.data.class_status == 'Missed'"
        class="el-icon-close color-red"
        style="font-size: 20px; position: absolute; right: 10px; top: 10px"
      ></i>
      <div
        class="pl-2 text-truncate"
        style="font-weight: 700; color: rgba(0, 0, 0, 0.75)"
        :style="{ paddingTop: `${fractionalTopPadding}px` }"
      >

        <div v-if="event.data.course_units_with_batch.length > 3">
          <div style="font-weight: 500; font-size: 0.9em">
            {{ event.data.course_units_with_batch.length }} Modules
          </div>
        </div>

        <div v-else>
          <div
            v-for="(courseUnit, index) in event.data.course_units_with_batch"
            :key="index"
          >
            {{ courseUnit}}
          </div>
        </div>

      </div>
      <div
        class="pl-2"
        style="font-weight: 700; color: rgba(0, 0, 0, 0.25)"
      >
        {{ event.type }}
      </div>
      <div
        class="pl-2 pt-2"
        style="font-weight: 700; color: rgba(0, 0, 0, 0.55); font-size: 0.73em"
      >
        <nobr> {{ `${event.data.start_time} - ${event.data.end_time}` }}</nobr>
      </div>
    </div>
  </el-popover>
</template>

<script>
import { format, parseISO, isAfter, isEqual } from "date-fns";
import { Popover } from "element-ui";
export default {
  components: {
    "el-popover": Popover,
  },
  data() {
    return {
      length: 70,
      isBusy: false,
    };
  },
  computed: {
    fractionalLength() {
      const hoursStart = this.event.data.start_time.substr(0, 2);
      const minutesStart = this.event.data.start_time.substr(3, 2);
      const modifierStart = this.event.data.start_time.substr(5, 2);

      const hoursEnd = this.event.data.end_time.substr(0, 2);
      const minutesEnd = this.event.data.end_time.substr(3, 2);
      const modifierEnd = this.event.data.end_time.substr(5, 2);

      var timeStart = new Date(
        `${this.event.data.date} ${hoursStart}:${minutesStart} ${modifierStart}`
      );
      var timeEnd = new Date(
        `${this.event.data.date} ${hoursEnd}:${minutesEnd} ${modifierEnd}`
      );

      var diff = (timeEnd - timeStart) / 60000; //dividing by seconds and milliseconds

      var minutes = diff % 60;
      var hours = (diff - minutes) / 60;

      return this.length * hours + (minutes / 60) * 70;
    },

    fractionalTopPadding() {
      if (this.fractionalLength == 70) return 8;
      return (this.fractionalLength - 70) * 0.55;
    },

    fractionalTopMarging() {
      const minutesStart = +this.event.data.start_time.substr(3, 2);
      return (minutesStart / 60) * this.length;
    },

    formattedDate() {
      return (dateString) => format(parseISO(dateString), "do MMMM yyyy");
    },

    isDateToday() {
      return isEqual(parseISO(this.event.data.date), parseISO(this.dateToday));
    },

    isInFuture() {
      return isAfter(parseISO(this.event.data.date), parseISO(this.dateToday));
    },
  },
  methods: {
  },
  mounted() {},
  props: {
    event: {
      required: true,
      type: Object,
    },
    dateToday: {
      required: true,
      type: String,
    },
  },
};
</script>

<style scoped>
.event-item {
  cursor: pointer !important;
}

.disabled {
  color: rgba(0, 0, 0, 0.3) !important;
}
.color-red {
  color: red !important;
}
.color-green {
  color: rgb(7, 206, 7) !important;
}
</style>