<template>
  <section class="container">
    <div class="content">
      <div class="row w-100 mb-4">
        <div class="col-12 pl-3 px-0 mb-0 text-left">
          <BreadCrumbComponent :pageTitles="['Tutorials']" />
        </div>
      </div>
      <div class="row w-100">
        <div class="col-12 text-left">
          <div
            style="
              font-size: 20px;
              font-weight: 400;
              color: var(--el-app-primary);
              margin-bottom: 15px;
            "
          >
            <strong>Tutorials</strong>
          </div>
        </div>
      </div>
    <TutorialsComponent />
    </div>
  </section>
</template>

<script>
import TutorialsComponent from "@/components/tutorials-component.vue";
import BreadCrumbComponent from "../components/bread-crumb-component";
export default {
  components: { BreadCrumbComponent,TutorialsComponent  },
  data() {
    return {
    //   loading: false,
    //   loadingError: false,
    //   isFetching: true,
    //   isError: false,
    //   tutorials: [],
    //   tutorial: null,
    //   carouselKey: 1,
    };
  },

//   mounted() {
//     this.$refs.preloaderCarouselRef.$el.getElementsByClassName(
//       "owl-prev"
//     )[0].innerHTML = "<i class='fas fa-chevron-left'></i>";
//     this.$refs.preloaderCarouselRef.$el.getElementsByClassName(
//       "owl-next"
//     )[0].innerHTML = "<i class='fas fa-chevron-right'></i>";

//     this.fetchTutorials();
//   },
  methods: {
    // async fetchTutorials() {
    //   try {
    //     //If we havent been loading yet, let's Load
    //     if (!this.isFetching) {
    //       this.isFetching = true;
    //       this.isError = false;
    //     }
    //     let request = await this.$http.get(`students/fetch-tutorials`);
    //     if (
    //       request.data.success &&
    //       request.data.message == "TUTORIALS FETCHED SUCCESSFULLY"
    //     ) {
    //       this.tutorials = request.data.tutorials;
    //     } else {
    //       this.$rollbar.warning("STUDENTS FRONT END: Unexpected API response", {
    //         response: request.data,
    //         request,
    //       });
    //       throw "UNEXPECTED_RESPONSE_RECEIVED";
    //     }
    //   } catch (error) {
    //     if (error.message === "Network Error") {
    //       return this.showFailedMessage(
    //         "Connection Failed",
    //         "Unable to Connect. Please check your Internet Connection and try again."
    //       );
    //     }
    //     this.showFailedMessage(          "Fetching failed",
    //       "Unable to fetch Tutorials, please try again")
    //   } finally {
    //           this.isFetching = false;
    //     this.isError = true;
    //   }
    // },
  },
};
</script>

<style scoped>
.content {
  margin-top: 45px !important;
}
.ui-margin {
  margin-left: 42px !important;
  margin-right: 42px !important;
}

@media (max-width: 768px) {
  .content {
    margin-top: 15px !important;
  }
}

.btn.disabled,
.btn:disabled {
  opacity: 0.35;
}
</style>

<style>
hr.custom-classic-hr {
  /* Gradient transparent-color-transparent */
  border: 0;
  height: 1px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.205),
    rgba(0, 0, 0, 0)
  );
}
.owl-prev {
  position: absolute;
  left: -21px;
  top: 44%;
  padding: 6px 11px !important;
  border-radius: 30px !important;
}

.owl-next {
  position: absolute;
  right: -21px;
  top: 44%;
  padding: 6px 11px !important;
  border-radius: 30px !important;
}
</style>