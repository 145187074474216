<template>
  <div class="container-fluid px-0 h-100 my_content_class" style="text-align: left">
    <article>
      <section style="padding-top: 50px">
        <div class="container">
          <div class="row w-100 mb-4">
            <div class="col-12 pl-3 px-0 mb-0 text-left">
              <BreadCrumbComponent :pageTitles="['My Content']" />
            </div>
          </div>

          <div class="row w-100">
            <div class="col-10 text-left">
              <div
                style="
                  font-size: 20px;
                  font-weight: 400;
                  color: var(--el-app-primary);
                  margin-bottom: 15px;
                "
              >
                <strong>My Content</strong>
              </div>
            </div>
          </div>
          <div class="search_add_section">
            <div>
              <input
                type="text"
                v-model.trim="searchName"
                @input="onSearch"
                placeholder="Search by Resource Title"
                class="search_by_input"
                spellcheck="off"
              />
            </div>

            <div class="filter-options mx-md-3">
              <el-select
                v-model="selectedFilter"
                placeholder="Select"
                style="height: 100% !important"
              >
                <el-option
                  v-for="item in filterOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>

            <div>
              <el-select
                v-model="resourceTypeFilter"
                placeholder="Select"
                class="d-block pr-md-2"
                style="height: 40px !important"
              >
                <el-option
                  v-for="item in resourceTypeFilterOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>

            <div>
              <button
                type="button"
                @click="addResource"
                class="btn z-depth-0"
                v-ripple="'rgba(255, 255, 255, 0.35)'"
                style="
                  width: auto;
                  text-transform: capitalize;
                  height: 40px;
                  border-radius: 4px;
                  background-color: var(--el-app-primary);
                  position: relative;
                "
              >
                <span style="position: relative; bottom: 4px"
                  >Add a Resource</span
                >
              </button>
            </div>
          </div>
          <hr />
          <!-- {{ resources | json }} -->

          <div v-loading="isLoading" class="table-responsive">
            
            <data-tables-server
              :data="resources"
              :table-props="tableProps"
              :pagination-props="{ pageSizes: [10, 25, 50] }"
              type="expand"
              :total="totalResources"
              :current-page.sync="currentPage"
              :page-size="pageSize"
              @query-change="onTableChange"
            >
              <div slot="empty">
                <ScaleOut
                  v-if="isLoading && !isLoadingError"
                  class="mt-3"
                  :background="'#042F6C'"
                />
                <div
                  @click="getStaffResources"
                  v-else-if="!isLoading && isLoadingError"
                  style="cursor: pointer"
                >
                  Unable to Load Resources Now. Please click here to retry
                </div>
                <div v-if="!isLoading && !isLoadingError">No Resources</div>
              </div>

              <el-table-column
                label="#"
                :show-overflow-tooltip="false"
                label-class-name="text-center"
                class-name="text-center"
                width="80px"
              >
                <template slot-scope="scope">
                  <span>{{ scope.$index + 1 }}</span>
                </template>
              </el-table-column>

              <el-table-column
                label="Resource Title"
                :show-overflow-tooltip="false"
                label-class-name="text-left"
                width="auto"
              >
                <template slot-scope="scope">
                  <!-- {{ scope.row.resource_type }} -->
                  <div style="display: flex; align-items: center">
                    <div style="width: 40px; height: 40px; border-radius: 50%">
                      <div
                        v-if="scope.row.resource_type == 'video'"
                        style="
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          background-color: var(--vu-red);
                          color: white;
                          width: 40px;
                          height: 40px;
                          object-fit: cover;
                          border-radius: 100%;
                        "
                      >
                        <span
                          class="fas fa-video"
                          style="font-size: 20px"
                        ></span>
                      </div>
                      <div
                        v-else
                        style="
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          color: white;
                          width: 40px;
                          height: 40px;
                          object-fit: cover;
                          border-radius: 100%;
                        "
                      >
                        <img
                          :src="
                            require(`@/assets/img/${documentImage(
                              scope.row.path_url
                            )}`)
                          "
                          alt=""
                          style="height: 40px; width: 40px"
                        />
                      </div>
                    </div>
                    <div style="margin-left: 10px">
                      <span>{{ scope.row.title }}</span
                      ><br />
                      <!-- <span showing years on edit style="font-weight: 600;">Email: </span><span>{{ scope.row.email }}</span> -->
                    </div>
                  </div>
                </template>
              </el-table-column>

              <el-table-column
                label="Resource info."
                :show-overflow-tooltip="false"
                label-class-name="text-left"
                width="auto"
              >
                <template slot-scope="scope">
                  <span>Uploaded on:</span>
                  <strong style="font-weight: 500">
                    {{ formattedDate(scope.row.created_at) }} </strong
                  ><br />

                  <!-- <span>Uploaded By:</span>
                  <span v-if="scope.row.creator">
                    <strong>
                      {{ scope.row.creator.first_name }}
                      {{ scope.row.creator.last_name }}
                    </strong>
                  </span> -->
                  File Size:
                  <strong style="font-weight: 500">
                    {{ toFileSize(scope.row.file_size) }}</strong
                  >
                  

                  <div v-if="scope.row.is_available == false">
                    <div style="color: green">Available from <strong
                        style="font-weight: 500"
                        v-if="scope.row.maturity_date"
                      >
                        {{ formattedDate(scope.row.maturity_date) }}</strong></div>

                  </div>
                  <div v-else-if="scope.row.is_available == true">
                    <div style="color: green"> <i class="el-icon-warning-outline"></i> Resource Available</div>
                  </div>

                  <div>
                    <span
                      v-if="scope.row.is_approved == 'APPROVED'"
                      style="color: green; font-weight: 500"
                    >
                      <i class="el-icon-check"></i> Approved by QA</span>
                    <span
                      v-if="scope.row.is_approved == 'REJECTED'"
                      style="color: red; font-weight: 500"
                    >
                      <i class="el-icon-close"></i> Rejected by QA</span>
                    <span
                      v-if="scope.row.is_approved == 'PENDING'"
                      style="color: orange; font-weight: 500"
                    >
                      <i class="el-icon-alarm-clock"></i> Pending QA
                      approval</span>
                  </div>
                </template>
              </el-table-column>

              <el-table-column
                label="Week"
                :show-overflow-tooltip="false"
                label-class-name="text-left"
                width="auto"
              >
                <template slot-scope="scope">
                  <div v-if="scope.row.week_no">
                    Week <strong style="font-weight: 500"> {{ scope.row.week_no }} </strong>
                  </div>
                  <div>
                    <span v-if="scope.row.start_week_day">
                      From: 
                      <strong>{{ formattedDate(scope.row.start_week_day) }}</strong>
                    </span><br/>
                    <span v-if="scope.row.end_week_day">
                      To: 
                      <strong>{{ formattedDate(scope.row.end_week_day) }}</strong>
                    </span>
                  </div>
                </template>
              </el-table-column>

              <el-table-column label="Resource Audience">
                <template slot-scope="scope">
                  <span>
                    <div v-if="scope.row.course_unit_with_intake_resources.length == 0">
                      <p class="show-more-intakes" @click="showIntakes(scope.row.resource_id)">View Intakes</p>
                    </div>
                    <div v-else-if="
                        scope.row.course_unit_with_intake_resources.length < 6
                      ">
                      <div
                        v-for="(intakes, index) in scope.row.course_unit_with_intake_resources"
                        :key="index"
                      >
                        <ul style="font-weight: 500">
                          <li>
                            <span>
                              <span>{{ intakes.course_unit.course_unit_code }}</span>
                              <span v-if="
                                  scope.row.resource_target ==
                                  'COURSE_UNIT_WITH_COURSE_INTAKE_BATCH'
                                ">
                                -
                                <span>
                                  {{
                                    intakes.course_intake_batch.course
                                      .course_code
                                  }}

                                  {{ intakes.course_intake_batch.cohort }}
                                </span>
                              </span>
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div v-else>
                      <ul>
                        <li>
                          {{
                            scope.row.course_unit_with_intake_resources.length
                          }}
                          {{scope.row.resource_target == "COURSE_UNIT" ? "Modules" : "Intakes"}}
                        </li>
                      </ul>
                    </div>
                  </span>
                </template>
              </el-table-column>

              <el-table-column
                label="Actions"
                label-class-name="text-center"
                class-name="text-center"
                style="display: none"
              >
                <template slot-scope="scope">
                  <el-row style="display: flex; justify-content: center">

                    
                    <el-button
                      type="primary"
                      icon="el-icon-more"
                      circle
                      @click="viewContent(scope.row)"
                      style="margin-right: 10px"
                    >
                    </el-button>

                    <el-button
                      type="warning"
                      icon="el-icon-edit"
                      circle
                      @click="editResource(scope.row.resource_id)"
                    >
                    </el-button>

                    <el-button
                      type="danger"
                      icon="el-icon-delete"
                      circle
                      @click="onDeleteResource(scope.row.resource_id)"
                    >
                    </el-button>
                  </el-row>
                </template>
              </el-table-column>
            </data-tables-server>
          </div>
        </div>
      </section>
    </article>

    <article>
      <section>
        <el-dialog title="Resource Content" :visible.sync="dialogTableVisible">
          <div v-loading="loading">
            <div class="row">
              <div class="col-md-6">
                <div style="display: flex; align-items: center">
                  <!-- <div
                    v-if="resourceData.path_url"
                    style="width: 40px; height: 40px; border-radius: 50%"
                  >
                    <div
                      v-if="resourceData.resource_type = 'video'"
                      style="
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: var(--vu-red);
                        color: white;
                        width: 40px;
                        height: 40px;
                        object-fit: cover;
                        border-radius: 100%;
                      "
                    >
                      <span class="fas fa-video" style="font-size: 20px"></span>
                    </div>
                    <div
                      v-else
                      style="
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: white;
                        width: 40px;
                        height: 40px;
                        object-fit: cover;
                        border-radius: 100%;
                      "
                    >
                      <img
                        :src="
                          require(`@/assets/img/${documentImage(
                            resourceData.path_url
                          )}`)
                        "
                        alt=""
                        style="height: 40px; width: 40px"
                      />
                    </div>
                  </div> -->
                  <div>
                    <strong style="font-weight: 500;">
                      Title</strong
                    >: {{ resourceData.title }}
                  </div>
                </div>
               <div
                class="pb-2"
                v-if="resourceData.created_at"
              >
                <strong style="font-weight: 500">Uploaded On</strong>:
                {{ formattedDate(resourceData.created_at) }}
              </div>
              <div
                class="pb-2"
                v-if="resourceData.creator"
              >
                <strong style="font-weight: 500">Uploaded By</strong>:
                {{ resourceData.creator.first_name }}
                {{ resourceData.creator.last_name }}
              </div>
              <div
                class="pb-2"
                v-if="resourceData.maturity_date"
              >
                <strong style="font-weight: 500">Publish date</strong>:
                {{ formattedDate(resourceData.maturity_date) }}
              </div>

                <p>
                  <strong style="font-weight: 500">Status</strong>:
                  <span
                    v-if="resourceData.is_approved == 'APPROVED'"
                    style="color: green; font-weight: 500"
                  >
                    <i class="el-icon-check"></i> Approved
                  </span>
                  <span
                    v-if="resourceData.is_approved == 'REJECTED'"
                    style="color: red; font-weight: 500"
                  >
                    <i class="el-icon-close"></i> Rejected
                  </span>
                  <span
                    v-if="resourceData.is_approved == 'PENDING'"
                    style="color: orange; font-weight: 500"
                  >
                    <i class="el-icon-alarm-clock"></i> Pending
                  </span>
                </p>

                <hr />
                <div>
                  <div>
                    <el-button
                      type="primary"
                      @click="viewResource(resourceData.resource_id)"
                      round
                      ><i class="el-icon-view"></i> View</el-button
                    >
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <h2 style="font-size: 1.2em; font-weight: 800">
                  Resource Audience
                </h2>

                <div
                  v-for="(
                    intakes, index
                  ) in resourceData.course_unit_with_intake_resources"
                  :key="index"
                  class="intakes_list"
                >
                  <ul style="font-weight: 400">
                    <li>
                     <div class="text-truncate">
                        <span
                          style="text-transform: upperCase; font-weight: 600"
                          >{{ intakes.course_unit.course_unit_code }}
                        </span>
                        <span
                          v-if="
                            resourceData.resource_target ==
                            'COURSE_UNIT_WITH_COURSE_INTAKE_BATCH'
                          "
                        >
                          -
                          <span
                            style="text-transform: upperCase; font-weight: 600"
                          >
                            {{ intakes.course_intake_batch.course.course_code }}
                            {{ toMonthName(intakes.course_intake_batch.month) }}/
                            {{ intakes.course_intake_batch.year }}
                            {{ intakes.course_intake_batch.cohort }}
                          </span>
                        </span>
                        <span style="text-transform: capitalCase">
                          -
                          {{ intakes.course_unit.title }}
                        </span>
                        <!-- <span>({{ intakes.stream.classroom.short_name }}) </span> -->
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </el-dialog>
      </section>
    </article>
  </div>
</template>

<script>
  import ScaleOut from "@/components/scale-out-component.vue";
  import BreadCrumbComponent from "../components/bread-crumb-component";
  import getMonthName from "../helpers/getMonthName";
  import { format, parseISO } from "date-fns";
  import debounce from "debounce";
  const fileSize = require("filesize");

  export default {
    components: { ScaleOut, BreadCrumbComponent },

    data() {
      return {
        addStudentsModal: false,
        addBatchModal: false,
        editBatchModal: false,
        dialogVisible: false,

        isUploadingFile: false,
        dialogTableVisible: false,
        resourceData: {},

        resources: [],
        loading: false,
        isLoading: false,
        isLoadingError: false,

        submiting: false,
        isBusy: false,
        deleting: false,
        responseData: "",
        Resources: [],
        courses: [],
        batch_students: [],

        searchName: "",
        totalResources: 0,
        currentPage: 1,
        pageSize: 10,

        tableProps: {
          border: true,
          stripe: true,
        },

        filters: [
          {
            prop: "name",
            value: "",
          },
        ],

        tableProps2: {
          border: true,
          stripe: true,
        },

        filters2: [
          {
            prop: "first_name",
            value: "",
          },
        ],

        id_edit: "",
        batchForm: {
          year: "",
          month: "",
          created_by: this.$store.state.userId,
          course_id: "",
        },
        rules: {
          year: [
            { required: true, message: "Please input year", trigger: "blur" },
          ],
          month: [
            { required: true, message: "Please input month", trigger: "blur" },
          ],
          course_id: [
            { required: true, message: "Please add course", trigger: "change" },
          ],
        },

      selectedFilter: "Show All",
      filterOptions: [
        {
          label: "Show All",
          value: "",
        },
        {
          label: "Show Approved",
          value: "APPROVED",
        },
        {
          label: "Show Rejected",
          value: "REJECTED",
        },
        {
          label: "Show Pending",
          value: "PENDING",
        },
      ],

       resourceTypeFilter: "Show All",
       resourceTypeFilterOptions: [
        {
          label: "Show All",
          value: "",
        },
        {
          label: "Show Notes",
          value: "NOTES",
        },
        {
          label: "Show Videos",
          value: "VIDEO",
        },
        {
          label: "Show Past Papers",
          value: "PAST_PAPERS",
        },
      ],

      };
    },

    computed: {
      getInitials() {
        return (nameString) => {
          //  format(parseISO(dateString), "do MMMM yyyy");
          var names = nameString.split(" "),
            initials = names[0].substring(0, 1).toUpperCase();
          if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
          }
          return initials;
        };
      },

      toFileSize() {
        return (size) => {
          return fileSize(size);
        };
      },

      resourcesData() {
        return this.resources.filter((resource) => {
          if (this.selectedFilter === "Show Approved")
            return resource.is_approved === "APPROVED";

          if (this.selectedFilter === "Show Rejected")
            return resource.is_approved === "REJECTED";

          if (this.selectedFilter === "Show Pending")
            return resource.is_approved === "PENDING";

          if (this.selectedFilter === "Show Notes")
            return resource.resource_type === "notes";

          if (this.selectedFilter === "Show Videos")
            return resource.resource_type === "video";

          if (this.selectedFilter === "Show Course work")
            return resource.resource_type === "course works";

          if (this.selectedFilter === "Show Past Papers")
            return resource.resource_type === "past papers";

          if (this.selectedFilter === "Show PDF")
            return resource.file_extension === "pdf";

          if (this.selectedFilter === "Show word Documents")
            return resource.file_extension === "docx";

          if (this.selectedFilter === "Show Power Point")
            return resource.file_extension === "pptx";

          return true;
        });
      },

      documentImage() {
        return (filePath) => {
          let last_dot_index = filePath.lastIndexOf(".");
          const fileType = filePath.substr(last_dot_index + 1)?.toLowerCase();

          if (fileType == "pdf" || fileType == "wps-office.pdf") return "pdf.png";
          if (fileType == "xls" || fileType == "xlsx") return "excel.png";
          if (fileType == "ppt" || fileType == "pptx") return "ppt.png";
          if (fileType == "zip" || fileType == "rar") return "zip.png";
          return "word.png";
        };
      },
    },

    watch: {
      selectedFilter() {
        this.onSearch();
      },
      resourceTypeFilter() {
        this.onSearch();
      },
    },

    mounted() {
      this.getStaffResources();
    },

    methods: {
      formattedDate(dateIso) {
        return format(parseISO(dateIso), "do MMM yyyy");
      },

      toMonthName(monthNum) {
        return getMonthName(monthNum, true);
      },

      onSearch: debounce(function () {
        this.getStaffResources();
      }, 500),

      async onTableChange(queryInfo) {
        if (queryInfo) {
          if (queryInfo.type == "page" || queryInfo.type == "size") {
            this.currentPage = queryInfo.page;
            this.pageSize = queryInfo.pageSize;
            this.getStaffResources();
          }
        }
      },

      viewContent(rData) {
        this.resourceData = rData;
        this.dialogTableVisible = true;
      },

      openBatchModal() {
        this.addBatchModal = true;
        this.batchForm.year = "";
        this.batchForm.month = "";
        this.batchForm.course_id = "";
      },

      async getStaffResources() {
        this.isLoading = true;
        this.isLoadingError = false;
        try {
          //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
          let request = await this.$http.get(
            `resources/fetch-by-lecturer/${this.$store.state.userId}?page=${this.currentPage}&pageSize=${this.pageSize}&searchName=${this.searchName}&showOnly=${this.selectedFilter}&resourceType=${this.resourceTypeFilter}`
          );
          if (
            request.data.success &&
            request.data.message === "STAFF_RESOURCES_FETCHED_SUCCESSFULLY"
          ) {
            // this.lecturerCourseUnits = request.data.lecturer_course_units;
            this.resources = request.data.resources;
          } else throw "UNEXPECTED_RESPONSE_RECEIVED";
        } catch (error) {
          this.isLoadingError = true;
          //TODO: REPORT THIS TO AN ERROR REPORTING SERVICE
          this.showFailedMessage(
            "Failed",
            "Unable to fetch Resources now, please try again"
          );
        } finally {
          this.isLoading = false;
        }
      },

      studentProfile(sId) {
        this.$router.push({ path: `student-profile/${sId}` });
      },

      addResource() {
        this.$router.push({ path: `upload-to-multiple-cohorts` });
      },

      editResource(resource_id) {
        this.$router.push({
          path: `update-to-multiple-cohorts/${resource_id}`,
        });
      },

      async onDeleteResource(resourceID) {
        try {
          let logOut = await this.$confirm(
            "This File will be permanently deleted, are you sure it's what you want to do?",
            "Delete File",
            {
              confirmButtonText: "Yes Delete",
              cancelButtonText: "Cancel",
              type: "warning",
            }
          );

          if (logOut) {
            this.isLoadingID = resourceID;

            try {
              this.isLoading = true;
              let request = await this.$http.delete(
                `resources/delete-one/${resourceID}`
              );
              if (
                request.data.success &&
                request.data.message == "RESOURCE DELETED SUCCESSFULLY"
              ) {
                this.showSuccessMessage(
                  "File Deleted",
                  "The Selected File was successfuly Deleted."
                );
                this.isLoading = false;
                this.getStaffResources();
                //Tell the Parent element that an item was deleted such that we just remove it from the list instead of fetching again
                this.$emit("resource-deleted", resourceID);
              } else throw "UNEXPECTED_RESPONSE";
            } catch (error) {
              // eslint-disable-line no-unused-vars

              this.showFailedMessage(
                "Delete Failed",
                "Unable to Delete Selected File. Please try again"
              );
            } finally {
              this.isLoadingID = "";
            }
          }
        } catch (error) {
          // eslint-disable-line no-unused-vars
        }
      },

      viewResource(resource_id) {
        this.$router.push({ path: `${resource_id}/resource-content-details` });
      },

      async showIntakes(resourceId){
        let request = await this.httpRequest({
          method: "GET",
          url: `resources/fetch-intakes/${resourceId}`,
          loadingPropertyName: "isLoading",
          errorLoadingPropertyName: "loadingError",
          showSuccessMessage: false,
        });

        if (
          request &&
          request.success &&
          request.message == "Resource intakes fetched successfully"
        ) {
          this.resources = this.resources.map((resource) => {
            if(resource.resource_id == request.resource.resource_id){
              resource.course_unit_with_intake_resources = request.resource.course_unit_with_intake_resources
            }
            return resource
          });
        }
      },
    },
  };
</script>

<style scoped>
* {
  box-sizing: border-box;
}

input[type="text"],
select,
textarea {
  width: 100%;
  padding: 9px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  background-color: #ffffff;
  font-size: 0.9em;
}

.search_add_section {
  display: flex;
  align-items: center;
  justify-content: start;
}

.intakes_list ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.intakes_list ul li {
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 5px;
  font-size: 0.8em;
}

.profile_img img {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  object-fit: cover;
  margin-right: 5px;
}

.to-an-array {
  display: flex;
}

.to-an-array > div {
  margin-right: 20px;
  background-color: #eeeeee;
  padding: 10px 10px 0px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#myInput {
  background-image: url("/css/searchicon.png");
  background-position: 10px 10px;
  background-repeat: no-repeat;
  width: 100%;
  font-size: 14px;
  padding: 5px 20px 5px 40px;
  border: 1px solid #ddd;
}

#myTable {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #ddd;
  font-size: 16px;
}

#myTable th,
#myTable td {
  text-align: left;
  padding: 12px;
}

h2 {
  text-align: center;
  padding: 20px 0;
}

.table-bordered {
  border: 1px solid #ddd !important;
}

table caption {
  padding: 0.5em 0;
}

@media screen and (max-width: 767px) {
  table caption {
    display: none;
  }
  .search_add_section {
    display: block;
  }
  .search_by_input{
    width: 100% !important;
    margin-top: .4rem !important;
    margin-right: 0 !important;
  }
}

.p {
  text-align: center;
  padding-top: 140px;
  font-size: 14px;
}

th > .cell {
  color: rgba(0, 0, 0, 0.836) !important;
  font-weight: 600 !important;
  font-size: 13px !important;
}
el-table th > .cell {
  color: black !important;
  font-weight: 500;
}
.el-table .cell {
  font-size: 0.9em;
  line-height: 23px;
  font-weight: 300;
}
.my_content_class .el-input input {
  height: calc(1.55rem) !important;
  padding: 0.075rem 0.05rem !important;
  font-size: 0.8rem !important;
  font-weight: 400 !important;
  color: #495057 !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.my_content_class .el-input input:focus {
  color: #495057 !important;
  background-color: #fff !important;
  border-color: #80bdff !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25) !important;
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .eword {
    display: none;
  }
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .eword {
    display: none;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  .eword {
    display: none;
  }
}
</style>

<style>
  
.my_content_class .filter-options .el-select .el-input__inner {
  cursor: pointer;
  height: 40px !important;
  width: 100% !important;
  padding-left: 10px !important;
}

</style>