<template>
  <div id="app">
    <router-view />

    <div
      class="staging_env_banner"
      v-if="isStagingEnv"
    >
      Staging Environment
    </div>

  </div>
</template>

<script>
import "firebase/firestore";
import "firebase/messaging";
const isStagingEnv = process.env.VUE_APP_IS_STAGING;
export default {
  data() {
    return {
      installingNotification: null,
      forceReload: false,
      isStagingEnv,
    };
  },
  computed: {
    forcedUpdateLastChecked() {
      return this.$store.state.forcedUpdateLastChecked;
    },
  },

  watch: {
    forcedUpdateLastChecked() {
      //Instruct the app to immediately check for updates as it means there's a NEW VERSION

      this.forceReload = true;
      this.serviceWorkerCheckUpdates();
    },
  },
  methods: {
    serviceWorkerCheckUpdates() {
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        let serviceWorker = registrations.find(
          (worker) =>
            worker.active &&
            worker.active.scriptURL.includes("service-worker.js")
        );
        //If we found it, attempt to fetch updates
        if (serviceWorker) {
          serviceWorker.update();
        }
      });
    },
  },

  created() {
    document.addEventListener(
      "visibilitychange",
      function () {
        document.dispatchEvent(
          new CustomEvent("appVisibilityChange", { detail: document.hidden })
        );
      },
      false
    );

    navigator.serviceWorker.addEventListener("message", (event) => {
      //If it is an update request, then update the app
      if (
        (event.data && event.data.title == "Check Update") ||
        (event.data.data && event.data.data.title == "Check Update")
      ) {
        this.serviceWorkerCheckUpdates();
      }

      //If it is an update request, then update the app
      if (
        (event.data && event.data.title == "Fetch Notifications") ||
        (event.data.data && event.data.data.title == "Fetch Notifications")
      ) {
        this.$store.commit("UPDATE_NOTIFICATIONS");
      }
    });

    document.addEventListener(
      "swUpdateFound",
      () => {
        this.installingNotification = this.$notify({
          title: "We are Updating",
          // type: "info",
          iconClass: "el-icon-loading mt-2",
          showClose: false,
          message: "Fetching new update now...",
        });
      },
      { once: true }
    );

    document.addEventListener(
      "swUpdated",
      () => {
        if (this.installingNotification) {
          this.installingNotification.close();
        }

        if (this.forceReload) {
          location.reload();
          return false;
        }

        this.$notify({
          title: "Update Available",
          dangerouslyUseHTMLString: true,
          // iconClass: "fas fa-download",
          type: "info",
          showClose: false,
          onClick: () => {
            location.reload();
            return false;
          },
          message: `There is an update available. Please Click below to refresh
                      <button
                          v-on:click="loginUser"
                          v-if="!isBusy"
                          :disabled="$v.emailOrPhone.$invalid || $v.password.$invalid"
                          type="button"
                          class="mt-2 btn z-depth-0"
                          v-ripple="'rgba(255, 255, 255, 0.35)'"
                          style="
                            width: 100px;
                            text-transform: capitalize;
                            font-size: 13px;
                            height: 35px;
                            border-radius: 40px;
                            padding: 10px 10px;
                            background-color: var(--el-app-primary);
                          "
                        >
                          <span style="position: relative; bottom: 3px">Update</span>
                        </button>
                      `,
          duration: 0,
        });
      },
      { once: true }
    );
  },

  destroyed() {},
};
</script>

<style>
.staging_env_banner {
  background-color: var(--vu-red) !important;
  color: white;
  padding: 5px 10px;
  z-index: 2000;
  font-size: 0.9em;
  border-radius: 5px;
  position: fixed;
  bottom: 10px;
  right: 10px;
}
.cursor-pointer {
  cursor: pointer !important;
}

input:focus:not(.input-error) {
  color: #495057 !important;
  background-color: #fff !important;
  border-color: #80bdff !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25) !important;
}
.el-message-box__title {
  font-size: 17px;
  font-weight: 600;
  font-family: "Euclid Circular B";
}

.el-popper {
  font-family: "Euclid Circular B" !important;
}

.el-button--primary {
  color: #fff;
  background-color: var(--el-app-primary) !important;
  border-color: var(--el-app-primary) !important;
}

.el-message-box__content {
  color: #4d4e52;
  font-weight: 400;
  font-size: 13px;
  font-family: "Euclid Circular B";
}

.el-notification__content {
  text-align: left !important;
}

.el-notification.right {
  font-family: "Euclid Circular B" !important;
}
.bread-crumb-item {
  font-size: 0.8em;
  font-weight: 500;
  color: #919191;
  cursor: pointer;
}

.bread-crumb-item:hover,
.bread-crumb-home:hover {
  color: #000 !important;
  transition: 1s;
}

#app {
  font-family: "Euclid Circular B";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
input.legacy-input {
  height: calc(1.55rem) !important;
  padding: 5px;
  font-size: 0.8rem !important;
  font-weight: 400 !important;
  color: #495057 !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}
input.legacy-input:disabled {
  background-color: rgb(248, 246, 246) !important;
}
input.legacy-input.input-error {
  border: 1px solid red !important;
  box-shadow: none !important;
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 0.5s;
  /*  animation-iteration-count:  infinite;  */
}
input.legacy-input:focus:not(.input-error) {
  color: #495057 !important;
  background-color: #fff !important;
  border-color: #80bdff !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25) !important;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f6f6f6;
}

/* Handle */
::-webkit-scrollbar-thumb {
  width: 10px;
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  width: 10px;
  background: #042f6c;
  border-radius: 10px;
}

.card {
  box-shadow: 0 2px 5px 2px rgb(0 0 0 / 0%), 0 2px 10px 0 rgb(0 0 0 / 5%) !important;
}

button:disabled {
  cursor: not-allowed !important;
  pointer-events: all !important;
}
</style>
