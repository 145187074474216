<template>
  <div class="row w-100 anonymous-user-need-help-dialog">
    <div class="col-12 text-center">
      <div class="col-12" @click="dialogVisible = true"
        style="font-weight: 500; color: rgba(0, 0, 0, 0.5);
          font-size: 13px; cursor: pointer;">
        NEED HELP? CONTACT US
      </div>
    </div>

    <div class="contact_dialogs">
      <el-dialog
        title="Contact Help"
        top="12vh"
        :visible.sync="dialogVisible"
        :destroy-on-close="true"
        
        :before-close="handleClose"
      >
        <div v-loading="isSendingMessage">
          <div class="row">
            <div
              class="col-md-12 text-left"
              style="font-weight: 500; color: rgba(0, 0, 0, 0.8)"
            >
              Your Full Name

              <span v-show="$v.userHelpFullName.$error && !$v.userHelpFullName.required"
                class="pl-2" style="color: red; font-size: 0.8em">Required</span>
              <span v-show="$v.userHelpFullName.$error && !$v.userHelpFullName.maxLength"
                class="pl-2" style="color: red; font-size: 0.8em">Too Long</span>
              <div class="mt-1">
                <input type="text"
                  placeholder="Full Name "
                  @enter="sendMessage"
                  :class="{ 'input-error': $v.userHelpFullName.$error }"
                  v-model.trim="$v.userHelpFullName.$model"
                  class="legacy-input bg-white"
                  style="width: 100%; height: calc(2.4rem) !important"/>
              </div>
            </div>
            <div
              class="col-md-12 text-left mt-2"
              style="font-weight: 500; color: rgba(0, 0, 0, 0.8)">
              Your Email
              <span v-show="$v.userHelpEmailAddress.$error &&
                  !$v.userHelpEmailAddress.required"
                class="pl-2" style="color: red; font-size: 0.8em">Required</span>
              <span v-show="$v.userHelpEmailAddress.$error && !$v.userHelpEmailAddress.email"
                class="pl-2" style="color: red; font-size: 0.8em">Invalid Email</span>
              <div class="mt-1">
                <input type="text"
                  placeholder="Full Email" @enter="sendMessage"
                  :class="{ 'input-error': $v.userHelpEmailAddress.$error }"
                  v-model.trim="$v.userHelpEmailAddress.$model"
                  class="legacy-input bg-white"
                  style="width: 100%; height: calc(2.4rem) !important"/>
              </div>
            </div>
            <div class="col-md-12 text-left mt-2"
              style="font-weight: 500; color: rgba(0, 0, 0, 0.8)">
              Subject
              <span v-show="$v.userHelpSubject.$error && !$v.userHelpSubject.required"
                class="pl-2" style="color: red; font-size: 0.8em">Required</span>
              <span v-show="$v.userHelpSubject.$error && !$v.userHelpSubject.maxLength"
                class="pl-2" style="color: red; font-size: 0.8em">Too Long</span>

              <div class="mt-1">
                <el-select filterable :class="{ 'input-error': $v.userHelpSubject.$error }"
                  @keyup.enter="sendMessage" v-model="$v.userHelpSubject.$model" 
                  placeholder="Choose Subject" style="width: 100%">
                  <el-option
                    v-for="subject in subject_options"
                    :key="subject.id"
                    :value="subject.sub_value"
                    :label="subject.title">
                  </el-option>
                </el-select>
                
              </div>

            </div>
            <div class="col-md-12 text-left mt-2"
              style="font-weight: 500; color: rgba(0, 0, 0, 0.8)">
              Message
              <span v-show="$v.userHelpMessage.$error && !$v.userHelpMessage.required"
                class="pl-2" style="color: red; font-size: 0.8em">Required</span>
              <span v-show="$v.userHelpMessage.$error && !$v.userHelpMessage.maxLength"
                class="pl-2" style="color: red; font-size: 0.8em">Too Long</span>
              <div class="mt-1">
                <el-input type="textarea"
                  @enter="sendMessage"
                  :class="{ 'input-error': $v.userHelpMessage.$error }"
                  v-model="$v.userHelpMessage.$model" :autosize="{ minRows: 3, maxRows: 3}" placeholder="Type message here..."  style="width: 100% !important;">
                  </el-input>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-12 text-center" style="display: flex; align-items: center; justify-content: center;">
              <button
                type="button"
                :disabled="$v.$invalid"
                @click="sendMessage"
                :class="{ 'not-allowed': $v.$invalid }"
                class="btn z-depth-0"
                v-ripple="'rgba(255, 255, 255, 0.35)'"
                style="margin: 0px !important;
                  text-transform: capitalize;
                  height: 45px;
                  border-radius: 4px; width: 100%; 
                  background-color: var(--el-app-primary);
                "
              >
                <span style="position: relative; bottom: 1px">Send Message</span>
              </button>
            </div>
          </div>
        </div>
      </el-dialog>
    </div>

  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength, email } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],

  data() {
    return {
      isSendingMessage: false,
      dialogVisible: false,
      userHelpFullName: "",
      userHelpEmailAddress: "",
      userHelpSubject: "",
      userHelpMessage: "",

      subject_options: [
        {id: 1, title: "Finances", sub_value: "FINANCES"},
        {id: 2, title: "Quality assurance", sub_value: "QUALITY_ASSURANCE"},
        {id: 3, title: "Academics", sub_value: "ACADEMICS"},
        {id: 4, title: "Technical", sub_value: "TECHINICAL"}
      ]
    };
  },

  validations: {
    userHelpFullName: { required, maxLength: maxLength(50) },
    userHelpEmailAddress: { required, email },
    userHelpSubject: { required, maxLength: maxLength(50) },
    userHelpMessage: { required, maxLength: maxLength(100) },
  },

  methods: {
    handleClose(done) {
      this.clearInputs();
      this.$v.$reset();
      done();
    },

    clearInputs() {
      this.userHelpFullName = "";
      this.userHelpEmailAddress = "";
      this.userHelpSubject = "";
      this.userHelpMessage = "";
    },

    async sendMessage() {
      if (this.$v.$invalid) return;
      this.isSendingMessage = true;
      try {
        let request = await this.$http.post(`contact/add`, {
          email: this.userHelpEmailAddress,
          full_name: this.userHelpFullName,
          subject: this.userHelpSubject,
          message: this.userHelpMessage,
        });
        if (
          request.data.success &&
          request.data.message == "MESSAGE_ADDED_SUCCESSFULLY"
        ) {
          this.dialogVisible = false;
          this.clearInputs();
          this.$v.$reset();

          return this.showSuccessMessage(
            "Success",
            "Message submitted successfully"
          );
        }
        throw "UNEXPECTED_RESPONSE";
      } catch (error) {
        if (error.message == "Network Error") {
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        return this.showFailedMessage(
          "Sending Failed",
          "Unable to send message now, please try again"
        );
      } finally {
        this.isSendingMessage = false;
      }
    },
  },
};
</script>

<style scoped>

  input[type=text],[type=email],[type=file], select, textarea {
    width: 100%;
    padding: 10px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
    background-color: #FFFFFF;
    font-size: .9em;
  }

</style>

<style>
  .input-error {
    border: 1px solid red !important;
    box-shadow: none !important;
    /* Start the shake animation and make the animation last for 0.5 seconds */
    animation: shake 0.5s;
    /*  animation-iteration-count:  infinite;  */
  }
  .not-allowed {
    cursor: not-allowed !important;
    pointer-events: all !important;
  }
  select.browser-default {
    display: block !important;
    height: 30px !important;
    width: 105%;
    border: 1px solid #ced4da !important;
    border-radius: 0.25rem !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
    cursor: pointer;
  }
  .anonymous-user-need-help-dialog .el-dialog__title {
    font-weight: 500;
  }

  .anonymous-user-need-help-dialog .el-select .el-input__inner {
    cursor: pointer;
    height: 40px !important;
    width: 100% !important;
    padding-left: 10px !important;
  }

  .el-date-editor--date .el-input__inner,
  .el-range-editor .el-input__inner {
    cursor: pointer;
    height: 40px !important;
    width: 100% !important;
    padding-left: 45px !important;
  }
  .el-date-editor .el-range-separator {
    display: contents;
    font-size: 0.8em;
    width: 20px !important;
    white-space: nowrap;
  }

  .el-date-editor.el-range-editor.el-input__inner.el-date-editor--timerange {
    height: 45px !important;
    width: 100%;
  }
  .contact_dialogs .el-dialog {
    width: 30%;
  }
  /* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) {
    .contact_dialogs .el-dialog {
      width: 40%;
    }
  }

  /* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .contact_dialogs .el-dialog {
      width: 70%;
    }
  }

  /* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
  @media (min-width: 481px) and (max-width: 767px) {
    .contact_dialogs .el-dialog {
      width: 90%;
    }
  }

  /* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
  @media (min-width: 320px) and (max-width: 480px) {
    .contact_dialogs .el-dialog {
      width: 90%;
    }
  }
</style>
