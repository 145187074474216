<template>
  <div class="container-fluid px-0 h-100" style="text-align: left;">
    <article>
      <section style="padding-top: 50px;">
        <div class="container">

          <div class="row w-100 mb-4">
            <div class="col-12 pl-3 px-0 mb-0 text-left">
              <BreadCrumbComponent :pageTitles="['Examinations', 'Examination Submissions']" />
            </div>
          </div>

          <div class="row w-100">
            <div class="col-12 text-left">
              <div style="font-size: 20px; font-weight: 400; color: var(--el-app-primary); margin-bottom: 15px; ">
                <strong>"{{ examination.course_unit_code}} {{ examination.title }}" Student Submissions</strong>
              </div>
            </div>
          </div>

          <div class="search_add_section">
            <div class="">
              <input type="text" v-model.trim="filters[0].value" placeholder="Search by Title"  class="search_by_input" spellcheck="off"/>
            </div>
          </div>
          <hr/>

          <!--
          examination_id: "8b0080313603"
          duration_in_minutes: 5
          path_url: "https://victoria-university-elearning.s3.amazon.com/1624985179935.pdf"
          answer_path_url: "https://victoria-university-elearning.s3.amazon.com/1624985179935.pdf"
          time_allowed_for_upload_in_minutes: 5 -->

          <div v-loading="deleting" style=" width: 100%">
            <data-tables :data="students_doing_exam" :table-props="tableProps" :pagination-props="{ pageSizes: [5, 20, 25, 50, 100] }" type="expand" :filters="filters">
              
              <div slot="empty">
                <ScaleOut v-if="loading && !loadingError" class="mt-3" :background="'#164B70'"/>
                <div @click="getExams" v-else-if="!loading && loadingError" style="cursor: pointer">
                  Unable to Load Examination Now. Please click here to retry
                </div>
                <div v-if="!loading && !loadingError">No Examination</div>
              </div>

              <el-table-column label="#" :show-overflow-tooltip="true" label-class-name="text-center" class-name="text-center" width="80px">
                <template slot-scope="scope">
                  <span>{{ scope.$index + 1 }}</span>
                </template>
              </el-table-column>

              <el-table-column label="Student" :show-overflow-tooltip="true" label-class-name="text-left" width="auto">
                <template slot-scope="scope">
                  <div style="display:flex; align-items: center;">
                    <div  style="width:40px; height:40px; border-radius: 50%;">
                      <div v-if="scope.row.profile_picture" style="display: flex; align-items:center; justify-content: center; background-color: var(--vu-red); color: white; width:40px; height:40px; object-fit:cover; border-radius: 100%;">
                        <img :src="scope.row.profile_picture" class="img-fluid" style="width:40px; height:40px; object-fit:cover; border-radius: 100%;" />
                      </div>
                      <div v-if="scope.row.profile_picture == null" style="display: flex; align-items:center; justify-content: center; background-color: var(--vu-red); color: white; width:40px; height:40px; object-fit:cover; border-radius: 100%;">
                        <strong>{{ getInitials(`${scope.row.first_name} ${scope.row.last_name}` ) }}</strong>
                      </div>
                    </div>
                    <div style="margin-left: 10px;">
                      <span>{{ scope.row.first_name }} {{ scope.row.last_name }}</span><br/>
                      <span><strong style="font-weight: 600;">Reg No.</strong> {{ scope.row.registration_no }}</span>
                    </div>
                  </div>
                </template>
              </el-table-column>

              <el-table-column label="Intake" :show-overflow-tooltip="true" label-class-name="text-center" class-name="text-center">
                <template slot-scope="scope">
                  <strong>
                    {{ scope.row.course_intake_batch.course.course_code}} {{ scope.row.course_intake_batch.year}} {{ toMonthName( scope.row.course_intake_batch.month) }} ( {{ scope.row.course_intake_batch.cohort}})              </strong>
                </template>
              </el-table-column>

              <el-table-column label="Actions" :show-overflow-tooltip="true" label-class-name="text-center" class-name="text-center">
                <template slot-scope="scope">
                  <el-row>
                    <el-tooltip content="Answer Sheet" placement="top">
                      <el-button type="warning" @click="questionPaper(scope.row.student_id)" icon="el-icon-document-copy" circle></el-button>
                    </el-tooltip>
                  </el-row>
                </template>
              </el-table-column>

            </data-tables>
          </div>
          
            </div>
      </section>
    </article>
  </div>
</template>

<script>

  import { validationMixin } from "vuelidate";
  import ScaleOut from "@/components/scale-out-component.vue";
  import BreadCrumbComponent from "../../components/bread-crumb-component";
  import { format, parseISO } from "date-fns";
  import getMonthName from "../../helpers/getMonthName";

  export default {

    components: { ScaleOut, BreadCrumbComponent, },
    mixins: [validationMixin],

      data() {
          return {
              
            loading: false,
            loadingError: false,
            submiting: false,
            deleting: false,
            examId: this.$route.params.examId, //this is the Exam id from the browserresource: '',

            notices: [],
            students_doing_exam: [],
            examination: {},

            tableData: [],
            tableProps: {
            border: true,
            stripe: true,
            },

            filters: [
                {
                    prop: "title",
                    value: "",
                },
            ],

        };
      },

      computed: {

        createdBy() {
          return (creator)=> {
            if(!creator) return "N/A";
            return `${creator.first_name} ${creator.last_name}`;
          }
        },

        getInitials() {
                return (nameString) => {
                //  format(parseISO(dateString), "do MMMM yyyy");
                var names = nameString.split(" "),
                    initials = names[0].substring(0, 1).toUpperCase();
                if (names.length > 1) {
                    initials += names[names.length - 1].substring(0, 1).toUpperCase();
                }
                return initials;
                };
        },


        formattedDate() {
          return (dateString) => format(parseISO(dateString), "do MMM yyyy");
        },

      },

      validations: { },

      mounted() {
        this.getOneExam();
      },

      methods: {

        toMonthName(monthNum) {
          return getMonthName(monthNum, true);
        },


        handleClose(done) {
          this.clearInputs();
          this.$v.$reset();
          done();
        },

        clearInputs() {
          this.title = "";
          this.message = "";
          this.audience = "";
          this.course_intake_batch_ids = [];
          this.valid_upto = "";
        },

        async getOneExam() {
            try {
                this.loading = true;
                this.loadingError = false;
                let request = await this.$http.get(`examinations/fetch-one/${this.examId}`);
                if ( request.data.success && request.data.message == "EXAMINATION_FETCHED_SUCCESS_FULLY" ) { 
                  this.examination = request.data.examination;
                  this.students_doing_exam = request.data.examination.students_doing_exam;
                }
                else { this.$rollbar.warning(
            "ADMIN FRONT END: Unexpected API response while getting server response",
            request.data,
            request
          );
          throw "UNEXPECTED_RESPONSE"; }  
            } catch (error) {
                if (error.message == "Network Error") {
                    this.loadingError = true;
                    return this.showFailedMessage("Connection failed",
                    "A network error occurred, please try again.");
                }
                this.loadingError = true; 
                this.showFailedMessage("Unable to load Exam", "An unexpected Error occurred, please try again");
            } finally {
                this.loading = false; 
            }
        },
        questionPaper(examId){
          console.log(examId);
          this.$router.push( { path: `${examId}/course-work-details` });
        },

        examSubmission(examId){
          console.log(examId);
          this.$router.push( { path: `${examId}/exam-submissions` });
        },
        
      }

  }

</script>

<style scoped>
    * {
        box-sizing: border-box;
    }
    
    input[type=text], select, textarea {
        width: 100%;
        padding: 10px 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
        resize: vertical;
        background-color: #FFFFFF;
        font-size: .9em;
    }

    .search_add_section {
        display: flex; align-items: center; justify-content: space-between;
    }

    /* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
    @media (min-width: 768px) and (max-width: 1024px) {
    }

    /* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    }

    /* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
    @media (min-width: 481px) and (max-width: 767px) {
        .search_add_section {
        display: block; 
        }
        .addbtn {
        width: 100%;
        margin-top: 10px;
        }
        .search_by_input {
            width: 100% !important;
        }
    }

    /* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
    @media (min-width: 320px) and (max-width: 480px) {
        .search_add_section {
        display: block;
        }
        .addbtn {
        width: 100%;
        margin-top: 10px;
        }
        .search_by_input {
            width: 100% !important;
        }
    }
</style>

<style>
  
    .announcement_dialogs .el-dialog {
        width: 70%;
    }

    .search_by_input {
        width: 400px !important;
    }

    .announcement_dialogs .el-select .el-input__inner {
        cursor: pointer;
        height: 36px !important;
        width: 100% !important;
        padding-left: 10px !important;
        text-transform: capitalize;
    }
    .announcement_dialogs .el-button--primary {
        color: #fff;
        background-color: #409eff !important;
        border-color: #409eff !important;
    }

    el-table th > .cell {
        color: black !important;
        font-weight: 500;
    }
    .el-table .cell {
        font-size: 0.9em;
        line-height: 23px;
        font-weight: 300;
    }

    .announcement_dialogs .el-input input {
        font-size: 0.8rem !important;
        font-weight: 400 !important;
        color: #495057 !important;
        background-color: #fff !important;
        background-clip: padding-box !important;
        border: 1px solid #ced4da !important;
        border-radius: 0.25rem !important;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
    }

    .announcement_dialogs .el-input input:focus {
        color: #495057 !important;
        background-color: #fff !important;
        border-color: #80bdff !important;
        outline: 0 !important;
        box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25) !important;
    }

    .announcement_dialogs .el-upload-dragger {
        background-color: #fff;
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        box-sizing: border-box;
        width: 366px !important;
        height: 150px !important;
        text-align: center;
        position: relative;
        overflow: hidden;
    }

    .announcement_dialogs  .el-icon-upload:before {
        font-size: 50px;
    }

  /* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) {
    .announcement_dialogs .el-dialog {
      width: 50%;
    }
  }

  /* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .announcement_dialogs .el-dialog {
      width: 70%;
    }
  }

  /* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
  @media (min-width: 481px) and (max-width: 767px) {
    .announcement_dialogs .el-dialog {
      width: 90%;
    }
  }

  /* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
  @media (min-width: 320px) and (max-width: 480px) {
    .announcement_dialogs .el-dialog {
      width: 90%;
    }

  }
</style>
