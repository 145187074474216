<template>
  <div
    class="container-fluid px-0 h-100 my_batches_class"
    style="text-align: left"
  >
    <article>
      <section style="padding-top: 50px">
        <div class="container">
          <div class="row w-100 mb-4">
            <div class="col-12 pl-3 px-0 mb-0 text-left">
              <BreadCrumbComponent :pageTitles="['Resources']" />
            </div>
          </div>

          <div class="row w-100">
            <div class="col-10 text-left">
              <div style="font-size: 20px; font-weight: 400; color: var(--el-app-primary); margin-bottom: 15px;">
                <strong>Batches</strong>
              </div>
            </div>
          </div>

          <!-- <div style="margin-bottom: 20px; display: flex; align-items: center; justify-content: space-between;">
            <div class="row w-100 d-flex">
              <div class="col-md-5 mb-3">
                <input type="text" v-model.trim="filters[0].value" placeholder="Search by Course"
                spellcheck="off"/>
              </div>
            </div>
          </div>
          <hr /> -->

          <div
            v-loading="deleting"
            class="table-responsive"
          >
            <data-tables
              :data="batches"
              :table-props="tableProps"
              :pagination-props="{ pageSizes: [5, 25, 50, 100] }"
              type="expand"
              :filters="filters"
            >
              <div slot="empty">
                <ScaleOut
                  v-if="loading && !loadingError"
                  class="mt-3"
                  :background="'#042F6C'"
                />
                <div
                  @click="getBatches"
                  v-else-if="!loading && loadingError"
                  style="cursor: pointer"
                >
                  Unable to Load Batches Now. Please click here to retry
                </div>
                <div v-if="!loading && !loadingError">No Batches</div>
              </div>

              <el-table-column
                label="#"
                :show-overflow-tooltip="true"
                label-class-name="text-center"
                class-name="text-center"
                width="80px"
              >
                <template slot-scope="scope">
                  <span>{{ scope.$index + 1 }}</span>
                </template>
              </el-table-column>

              <el-table-column
                label="Cohort"
                :show-overflow-tooltip="true"
                label-class-name="text-left"
                width="auto"
                prop="name"
              >
                <template slot-scope="scope">
                  <span> {{  scope.row.course_intake_batch.course.course_code }} {{toMonthName(scope.row.course_intake_batch.month)}}/{{scope.row.course_intake_batch.year}} {{scope.row.course_intake_batch.cohort}}</span>
                </template>
              </el-table-column>

              <el-table-column
                label="Modules"
                :show-overflow-tooltip="true"
                label-class-name="text-left"
                width="auto"
                prop="name"
              >
                <template slot-scope="scope">
                  <!-- <div v-for="(item, i) in scope.row.intake_course_units" :key="i" >
                    {{ item.title }} - {{item.course_unit_code}}
                  </div> -->

                  <span>{{scope.row.course_unit.course_unit_code}} - {{scope.row.course_unit.title}}</span>

                </template>
              </el-table-column>

              <el-table-column
                label="Number of students"
                :show-overflow-tooltip="true"
                label-class-name="text-left"
              >
                <template slot-scope="scope">
                  <div
                    @click="showStudents(scope.row.course_intake_batch.course_intake_batch_id)"
                    style="cursor: pointer; width:100%; height:100%;"
                  >
                    <span style="font-weight: 700;">{{scope.row.course_intake_batch.batch_students}} {{ scope.row.course_intake_batch.batch_students == 1 ? 'Student' : 'Students' }} </span>
                  </div>
                </template>
              </el-table-column>

            </data-tables>
          </div>
        </div>
      </section>

      <section>
        <div class="container">

          <el-dialog
            title="Students in this Batch"
            :visible.sync="dialogVisible"
            width="80%"
          >
            <div v-loading="isBusy">
              <div v-if="batch_students.length == 0">No Students in this Batch.</div>
              <div v-else>
                <!-- <ul style="display: flex;  flex-wrap: wrap; margin:0px; list-style:none; padding:0px">
                        <li v-for="student in batch_students" :key="student.student_id" style="margin:5px; padding: 0px 10px 0px 0px; border-radius: 50px 20px  20px 50px; list-style:none; background-color: #eeeeee; position: relative;">
                        <div @click="studentProfile(student.student_id)" style="display: flex; cursor: pointer; border-radius: 50px 20px  20px 50px;">
                            <div class="profile_img">
                            <img :src="student.profile_picture" v-if="student.profile_picture"  alt="" class="img-fluid" />
                            <div v-if="student.profile_picture == null" style="display: flex; align-items:center; justify-content: center;margin-right:5px; background-color: var(--el-app-primary); color: white; width:30px; height:30px; object-fit:cover; border-radius: 100%;">
                                <strong>{{ getInitials(`${student.first_name} ${student.last_name}`) }}</strong>
                            </div>
                            </div>
                            <div style="display: flex; align-items: center;margin-right:5px;">
                            <small>{{ student.first_name }} {{ student.last_name }}</small>
                            </div>
                        </div>
                        </li>
                    </ul> -->

                <div style="margin-bottom: 20px; display: flex; align-items: center; justify-content: space-between;">
                  <div class="row w-100 d-flex">
                    <div class="col-md-5 mb-3">
                      <input
                        type="text"
                        class="legacy-input"
                        v-model="filters2[0].value"
                        placeholder="Search by name"
                        style="height: calc(1.8rem) !important"
                        spellcheck="off"
                      />
                    </div>
                  </div>
                </div>

                <div class="table-responsive">
                  <data-tables
                    :data="batch_students"
                    :table-props="tableProps2"
                    :pagination-props="{ pageSizes: [5, 25, 50, 100] }"
                    type="expand"
                    :filters="filters2"
                  >

                    <el-table-column
                      label="#"
                      :show-overflow-tooltip="true"
                      label-class-name="text-left"
                      width="30px"
                    >
                      <template slot-scope="scope">
                        <span>{{ scope.$index + 1 }}</span>
                      </template>
                    </el-table-column>

                    <el-table-column
                      label="Name"
                      :show-overflow-tooltip="true"
                      label-class-name="text-left"
                      width="auto"
                    >
                      <template slot-scope="scope">
                        <div style="display:flex; align-items: center;">
                          <div style="width:40px; height:40px; border-radius: 50%;">
                            <div
                              v-if="scope.row.profile_picture"
                              style="display: flex; align-items:center; justify-content: center; background-color: var(--el-app-primary); color: white; width:40px; height:40px; object-fit:cover; border-radius: 100%;"
                            >
                              <img
                                :src="scope.row.profile_picture"
                                class="img-fluid"
                                style="width:40px; height:40px; object-fit:cover; border-radius: 100%;"
                              />
                            </div>
                            <div
                              v-if="scope.row.profile_picture == null"
                              style="display: flex; align-items:center; justify-content: center; background-color: var(--el-app-primary); color: white; width:40px; height:40px; object-fit:cover; border-radius: 100%;"
                            >
                              <strong>{{ getInitials(`${scope.row.first_name} ${scope.row.last_name}` ) }}</strong>
                            </div>
                          </div>
                          <div style="margin-left: 10px;">
                            <span>{{ scope.row.first_name }} {{ scope.row.last_name }}</span><br />
                            <!-- <span showing years on edit style="font-weight: 600;">Email: </span><span>{{ scope.row.email }}</span> -->
                          </div>
                        </div>
                      </template>
                    </el-table-column>

                    <el-table-column
                      label="Registration Number"
                      :show-overflow-tooltip="true"
                      label-class-name="text-left"
                      width="auto"
                    >
                      <template slot-scope="scope">
                        <span>{{ scope.row.registration_no }}</span>
                      </template>
                    </el-table-column>

                    <el-table-column label="Phone Number">
                      <template slot-scope="scope">
                        <span>{{ scope.row.phone_number }}</span>
                      </template>
                    </el-table-column>

                  </data-tables>
                </div>
              </div>

            </div>

            <span
              slot="footer"
              class="dialog-footer"
            >
              <el-button @click="dialogVisible = false">Close</el-button>
            </span>
          </el-dialog>

        </div>
      </section>
    </article>
  </div>
</template>

<script>
import ScaleOut from "@/components/scale-out-component.vue";
import BreadCrumbComponent from "../components/bread-crumb-component";
import getMonthName from "../helpers/getMonthName";

export default {
  components: { ScaleOut, BreadCrumbComponent },

  data() {
    return {
      addStudentsModal: false,
      addBatchModal: false,
      editBatchModal: false,
      dialogVisible: false,

      loading: false,
      loadingError: false,
      submiting: false,
      isBusy: false,
      deleting: false,
      responseData: "",
      batches: [],
      courses: [],
      batch_students: [],

      searchBy: "Search by course title",

      tableProps: {
        border: true,
        stripe: true,
      },

      filters: [
        {
          prop: "name",
          value: "",
        },
      ],

      tableProps2: {
        border: true,
        stripe: true,
      },

      filters2: [
        {
          prop: "first_name",
          value: "",
        },
      ],

      id_edit: "",
      batchForm: {
        year: "",
        month: "",
        created_by: this.$store.state.userId,
        course_id: "",
      },
      rules: {
        year: [
          { required: true, message: "Please input year", trigger: "blur" },
        ],
        month: [
          { required: true, message: "Please input month", trigger: "blur" },
        ],
        course_id: [
          { required: true, message: "Please add course", trigger: "change" },
        ],
      },
    };
  },

  computed: {
    getInitials() {
      return (nameString) => {
        //  format(parseISO(dateString), "do MMMM yyyy");
        var names = nameString.split(" "),
          initials = names[0].substring(0, 1).toUpperCase();
        if (names.length > 1) {
          initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
      };
    },
  },

  mounted() {
    this.getBatches();
  },
  methods: {
    toMonthName(monthNum) {
      return getMonthName(monthNum, false).toUpperCase();
    },

    openBatchModal() {
      this.addBatchModal = true;
      this.batchForm.year = "";
      this.batchForm.month = "";
      this.batchForm.course_id = "";
    },

    async getBatches() {
      try {
        this.loading = true;
        this.loadingError = false;
        let request = await this.$http.get(
          `staff/lecturer-batches-with-course-units/${this.$store.state.userId}`
        );

        if (
          request.data.success &&
          request.data.message ==
            "LECTURER_COURSE_UNITS_WITH_BATCHES_FETCHED_SUCCESSFULLY"
        ) {
          this.batches = request.data.course_units_with_batches;
        } else {
          throw "ERROR OCCURED";
        }
      } catch (error) {
        console.log(error);
        if (error.message == "Network Error") {
          this.loadingError = true;
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }

        this.loadingError = true;
        this.showFailedMessage(
          "Unable to load Batches",
          "An unexpected Error occurred, please try again"
        );
      } finally {
        this.loading = false;
      }
    },

    async showStudents(batchId) {
      this.dialogVisible = true;
      this.isBusy = true;
      try {
        this.loading = true;
        this.loadingError = false;
        let request = await this.$http.get(
          `course-intake-batches/fetch-students/${batchId}`
        );
        if (request.data.success) {
          this.batch_students = request.data.batch.batch_students;
          this.dialogVisible = true;
        } else {
          throw "ERROR OCCURED";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        this.showFailedMessage(
          "Unable to load students in this Batch",
          "An unexpected Error occurred, please try again"
        );
      } finally {
        this.isBusy = false;
      }
    },

    studentProfile(sId) {
      this.$router.push({ path: `student-profile/${sId}` });
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

input[type="text"],
select,
textarea {
  width: 100%;
  padding: 10px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  background-color: #ffffff;
  font-size: 0.9em;
}

.profile_img img {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  object-fit: cover;
  margin-right: 5px;
}

.to-an-array {
  display: flex;
}

.to-an-array > div {
  margin-right: 20px;
  background-color: #eeeeee;
  padding: 10px 10px 0px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#myInput {
  background-image: url("/css/searchicon.png");
  background-position: 10px 10px;
  background-repeat: no-repeat;
  width: 100%;
  font-size: 14px;
  padding: 5px 20px 5px 40px;
  border: 1px solid #ddd;
}

#myTable {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #ddd;
  font-size: 16px;
}

#myTable th,
#myTable td {
  text-align: left;
  padding: 12px;
}

h2 {
  text-align: center;
  padding: 20px 0;
}

.table-bordered {
  border: 1px solid #ddd !important;
}

table caption {
  padding: 0.5em 0;
}

@media screen and (max-width: 767px) {
  table caption {
    display: none;
  }
}

.p {
  text-align: center;
  padding-top: 140px;
  font-size: 14px;
}

th > .cell {
  color: rgba(0, 0, 0, 0.836) !important;
  font-weight: 600 !important;
  font-size: 13px !important;
}
el-table th > .cell {
  color: black !important;
  font-weight: 500;
}
.el-table .cell {
  font-size: 0.9em;
  line-height: 23px;
  font-weight: 300;
}
.my_batches_class .el-input input {
  height: calc(1.55rem) !important;
  padding: 0.075rem 0.05rem !important;
  font-size: 0.8rem !important;
  font-weight: 400 !important;
  color: #495057 !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.my_batches_class .el-input input:focus {
  color: #495057 !important;
  background-color: #fff !important;
  border-color: #80bdff !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25) !important;
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .eword {
    display: none;
  }
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .eword {
    display: none;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  .eword {
    display: none;
  }
}
</style>
