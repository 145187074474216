<template>
  <div>
    <div>
      <el-dialog
        title="Select Lectures Range"
        width="400px"
        :visible.sync="showDialog"
        :destroy-on-close="true"
        :before-close="handleClose"
      >
        <div v-loading="submiting">
          <div class="row">
            <div class="col-12">
              <div class="block">
                <div class="pb-2 demonstration">Select period </div>
                <el-date-picker
                  type="daterange"
                  v-model="startAndEndTime"
                  :format="'dd MMMM yyyy'"
                  :value-format="'yyyy-MM-dd'"
                  range-separator="To"
                  start-placeholder="Start Date"
                  end-placeholder="End Date"
                  style="height: 40px !important"
                >
                </el-date-picker>
              </div>
            </div>
            <div
              class="pt-4 col-12 align-items-end"
              style="display:flex"
            >
              <el-button
                style="background-color: var(--el-app-primary); color: white; width: 100%; height: 2.6rem;margin-right: 7px"
                class="z-depth-0 addbtn"
                v-ripple="'rgba(255, 255, 255, 0.35)'"
                :disabled="!startAndEndTime"
                @click="onChoosePeriod"
              >Select Period</el-button>
            </div>
          </div>

        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      submiting: false,
      showDialog: false,
      startAndEndTime: null,
    };
  },

  props: {
    dialogVisible: {
      required: true,
      type: Boolean,
    },
  },

  watch: {
    dialogVisible() {
      this.showDialog = this.dialogVisible;
    },
  },
  methods: {
    handleClose() {
      this.$emit("on-close");
    },
    async onChoosePeriod() {
      if (!this.startAndEndTime) return;
      this.$emit("on-range-chosen", this.startAndEndTime);
    },
  },
};
</script>

<style>
</style>