<template>
  <section>
    <div id="sidebar-article">
      <div style="cursor: pointer">
        <div style="
            margin-bottom: 10px;
            margin-top: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
          ">
          <router-link to="/dashboard/home">
            <img
              :src="$store.state.logoImageUrl"
              style="height: 65px"
              alt=""
              class="mt-3 img-fluid"
            />
          </router-link>
        </div>

        <!-- <hr class=""> -->
      </div>

      <div
        class="theLists"
        style=""
      >
        <ul style="">
          <li v-on:click="closeNav()">
            <router-link to="/dashboard/home">
              <div
                class="side-link"
                :class="{ active: $route.name == 'Home' }"
              >
                <span><i class="fas fa-home"></i> Home</span>
              </div>
            </router-link>
          </li>
          <li
            v-on:click="closeNav()"
            v-if="!$store.getters.isNonTeachingStaff"
          >
            <router-link to="/dashboard/my-content">
              <div
                class="side-link"
                :class="{ active: $route.name == 'MyContent' }"
              >
                <span><i class="fas fa-video"></i> My Content</span>
              </div>
            </router-link>
          </li>
          <li
            v-on:click="closeNav()"
            v-if="!$store.getters.isNonTeachingStaff"
          >
            <router-link to="/dashboard/my-batches">
              <div
                class="side-link"
                :class="{ active: $route.name == 'MyBatches' }"
              >
                <span><i class="fas fa-window-restore"></i> My Intakes</span>
              </div>
            </router-link>
          </li>

          <li
            v-on:click="closeNav()"
            v-if="!$store.getters.isNonTeachingStaff"
            :class="$store.getters.canExamCRUD ? 'show_list' : 'hide_list'"
          >
            <router-link to="/dashboard/course-work-sets">
              <div
                class="side-link"
                :class="{
                  active:
                    $route.name == 'CourseWorkSets' ||
                    $route.name == 'EditCourseWorkSet',
                }"
              >
                <span><i class="fas fa-copy"></i> Course Work</span>
              </div>
            </router-link>
          </li>
          <li
            v-on:click="closeNav()"
            v-if="!$store.getters.isNonTeachingStaff"
          >
            <router-link to="/dashboard/lectures">
              <div
                class="side-link"
                :class="{
                  active:
                    $route.name == 'Lectures' ||
                    $route.name == 'AllLectures' ||
                    $route.name == 'LiveLecture',
                }"
              >
                <span><i class="fas fa-video"></i> Lectures</span>
              </div>
            </router-link>
          </li>

          <li
            v-on:click="closeNav()"
            v-if="!$store.getters.isNonTeachingStaff"
          >
            <router-link to="/dashboard/tutorials">
              <div
                class="side-link"
                :class="{
                  active:
                    $route.name == 'Tutorials' ||
                    $route.name == 'TutorialDetails',
                }"
              >
                <span><i class="fas fa-film"></i> Tutorials</span>
              </div>
            </router-link>
          </li>

          <li
            v-on:click="closeNav()"
            v-if="!$store.getters.isNonTeachingStaff"
          >
            <router-link to="/dashboard/my-calendar">
              <div
                class="side-link"
                :class="{ active: $route.name == 'MyCalendar' }"
              >
                <span><i class="fas fa-calendar-alt"></i>Timetable</span>
              </div>
            </router-link>
          </li>

          <li
            v-on:click="closeNav()"
            v-if="!$store.getters.isNonTeachingStaff"
            :class="$store.getters.canExamCRUD ? 'show_list' : 'hide_list'"
          >
            <router-link to="/dashboard/examinations">
              <div
                class="side-link"
                :class="{
                  active:
                    $route.name == 'ExaminationSets' ||
                    $route.name == 'Examinations' ||
                    $route.name == 'AddExamination' ||
                    $route.name == 'EditExamination' ||
                    $route.name == 'ExamDetails' ||
                    $route.name == 'AnswerSheet' ||
                    $route.name == 'ExamStatistics' ||
                    $route.name == 'AnswerSheet' ||
                    $route.name == 'ExamSubmissions',
                }"
              >
                <span><i class="fas fa-copy"></i> Examinations</span>
              </div>
            </router-link>
          </li>

          <li v-on:click="closeNav()">
            <router-link to="/dashboard/cafeteria">
              <div
                class="side-link"
                :class="{active: $route.name == 'Cafeteria' ||
                    $route.name == 'GenerateCoupon' ||
                    $route.name == 'PreviousCoupons'
                }"
              >
                <span><i class="el-icon-food"></i> Cafeteria</span>
              </div>
            </router-link>
          </li>

        </ul>
      </div>

      <div style="">
        <ul style="">
          <li v-on:click="closeNav()">
            <router-link to="/dashboard/settings">
              <div
                class="side-link"
                :class="{ active: $route.name == 'Settings' }"
              >
                <span><i class="fas fa-cog"></i> Settings</span>
              </div>
            </router-link>
          </li>
          <!-- <li v-on:click="closeNav()">
            <router-link to="/dashboard/help">
              <div
                class="side-link"
                :class="{ active: $route.name == 'Help' }"
              >
                <span><i class="far fa-question-circle"></i> Help</span>
              </div>
            </router-link>
          </li> -->
          <li v-on:click="onLogoutIntent">
            <div class="side-link">
              <span><i class="fas fa-sign-out-alt"></i> Logout</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  methods: {
    closeNav() {
      document.getElementById("mySidenav").style.width = "0";
    },

    async onLogoutIntent() {
      this.closeNav();
      try {
        let logOut = await this.$confirm(
          "You will be immediately logged out of your account, Continue?",
          "Confirm Log out",
          {
            confirmButtonText: "Logout",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        );

        if (logOut) {
          this.logOut();
        }
      } catch (error) {
        // eslint-disable-line no-unused-vars
      }
    },

    async logOut() {
      const loading = this.$loading({
        lock: true,
        text: "Logging you out...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      try {
        let request = await this.$http.post("/staff/logout");
        if (
          request.data &&
          request.data.success &&
          request.data.message == "LOGOUT_SUCCESSFUL"
        ) {
          loading.close();
          this.$store.commit("LOGOUT_USER");
          this.axios.defaults.headers.common["Authorization"] = "";
          this.$router.replace({ path: "/login" });
        }
      } catch (error) {
        this.showFailedMessage(
          "Logout failed",
          "Unable to log you out now, please try again"
        );
      } finally {
        loading.close();
      }
    },
  },
};
</script>

<style scoped>
.theLists {
  margin: 50px 0px;
  padding: 0px;
}
ul {
  padding: 0px;
}
ul li {
  list-style: none;
  text-align: left;
  font-weight: 500;
}
ul li i {
  margin-right: 5px;
}

.side-link {
  color: rgba(0, 0, 0, 0.74);
  font-weight: 500;
  padding: 10px 30px;
  cursor: pointer;
  transition: 0.5s;
}

.side-link.active {
  border-left: 6px solid var(--vu-red);
  color: var(--el-app-primary) !important;
  font-weight: 600;
}

.side-link:hover {
  background-color: #eeeeee;
  padding: 10px 40px;
  transition: 0.5s;
  color: #74a4c5;
}
.bg-white {
  background-color: white;
}
</style>
