<template>
  <el-dialog
    title="Live Lecture Details"
    :visible.sync="isVisible"
    width="40%"
    :before-close="beforeClose"
  >
    <div v-loading="lectureDetailsLoading || isBusy">
      <div class="container-fluid">
        <div class="row w-100">
          <div class="col-12">
            <div
              class="pr-3 pb-3"
              style="font-weight: 600"
            >Module(s):</div>
            <div
              class=""
              v-for="(
                moduleItem, index
              ) in liveLectureDetails.course_units_with_batch"
              :key="index"
            >
              {{ moduleItem }}
            </div>
          </div>
          <div class="col-12 pt-2">
            <span
              class="pr-3"
              style="font-weight: 600"
            >Total Students: </span>
            {{ liveLectureDetails.total_students }}
          </div>
          <div class="col-12 pt-2">
            <span
              class="pr-3"
              style="font-weight: 600"
            >Topic: </span>
            {{ liveLectureDetails ? liveLectureDetails.title : "N/A" }}
          </div>
          <div class="col-12 pt-2">
            <span
              class="pr-3"
              style="font-weight: 600"
            >Date: </span>
            <span v-if="liveLectureDetails">{{
              liveLectureDetails.date
                ? formattedDate(liveLectureDetails.date)
                : ""
            }}</span>
          </div>
          <div class="col-12 pt-2">
            <span
              class="pr-3"
              style="font-weight: 600"
            >Time: </span>
            <span v-if="liveLectureDetails">
              {{ liveLectureDetails.start_time }} to
              {{ liveLectureDetails.end_time }}</span>
          </div>

          <div class="col-12 pt-2">
            <span
              class="pr-3"
              style="font-weight: 600"
            >Status: </span>
            <span v-if="liveLectureDetails.lecture_status === 'IN_THE_PAST'">
              <span v-if="liveLectureDetails.class_status">
                <span
                  v-if="liveLectureDetails.class_status === 'Attended'"
                  class="badge badge-success py-1 px-2"
                >Attended</span>
                <span
                  v-if="liveLectureDetails.class_status === 'Missed'"
                  class="badge badge-danger py-1 px-2"
                >Missed</span>
              </span>
              <span v-else> N/A</span>
            </span>
            <span v-else-if="liveLectureDetails.lecture_status === 'TODAY_AND_LIVE'">
              Live / Ongoing</span>
            <span v-else-if="
                liveLectureDetails.lecture_status === 'TODAY_AND_UPCOMING' ||
                liveLectureDetails.lecture_status === 'UPCOMING'
              ">
              Upcoming</span>
            <span v-else> Lecture Ended</span>
            <!-- <span v-else> Still Processing Attendance</span> -->
          </div>

          <div
            class="col-12 pt-2"
            v-if="liveLectureDetails.class_status === 'Attended'"
          >
            <span
              class="pr-3"
              style="font-weight: 600"
            >Lecturer Check-in Time:
            </span>
            <span v-if="liveLectureDetails">{{
              liveLectureDetails.attendance_info.time_in
            }}</span>
          </div>

          <div
            class="col-12 pt-2"
            v-if="liveLectureDetails.class_status === 'Attended'"
          >
            <span
              class="pr-3"
              style="font-weight: 600"
            >Lecturer Check-out Time:
            </span>
            <span v-if="liveLectureDetails">{{
              liveLectureDetails.attendance_info.check_out_time
            }}</span>
          </div>
          <div
            class="col-12 pt-2"
            v-if="
              liveLectureDetails.class_status === 'Attended' &&
              liveLectureDetails.attendance_info.total_sessions > 0
            "
          >
            <span
              class="pr-3"
              style="font-weight: 600"
            >Lecturer's Total Sessions:
            </span>
            <span v-if="liveLectureDetails">{{ liveLectureDetails.attendance_info.total_sessions }}
              <span
                @click="fetchAttendanceSessions"
                v-if="sessions.length == 0"
                style="
                  font-size: 0.9em;
                  padding-left: 5px;
                  color: var(--el-app-primary);
                  font-weight: 500;
                  cursor: pointer;
                "
              >(View Details)</span>
            </span>
          </div>

          <div
            class="col-12 pt-2"
            v-if="sessions.length > 0"
          >
            <div
              class="pr-3"
              style="font-weight: 600"
            >Lecturer's Sessions</div>
            <div
              v-for="(session, index) in sessions"
              :key="index"
            >
              {{ index + 1 }} ). Entered at {{ session.time_in }} and Left at
              {{ session.time_out }}
            </div>
          </div>

          <div
            class="col-12 pt-2"
            v-if="liveLectureDetails.class_status === 'Attended'"
          >
            <span
              class="pr-3"
              style="font-weight: 600"
            >Lecturer Duration Attended:
            </span>
            <span v-if="liveLectureDetails">{{ liveLectureDetails.attendance_info.duration_attended }} ({{
                liveLectureDetails.attendance_info.percentage_attended
              }})</span>
          </div>

          <div class="col-12 pt-2">
            <hr />
            <span
              class="pr-3"
              style="font-weight: 600"
            >Lecturer: </span>
            <span v-if="liveLectureDetails.lecturer">
              {{ liveLectureDetails.lecturer.first_name }}
              {{ liveLectureDetails.lecturer.last_name }}</span>
          </div>
          <div class="col-12 pt-2">
            <span
              class="pr-3"
              style="font-weight: 600"
            >Scheduled By: </span>
            <span v-if="liveLectureDetails.scheduled_by">
              {{ liveLectureDetails.scheduled_by.first_name }}
              {{ liveLectureDetails.scheduled_by.last_name }}</span>
          </div>
          <div class="col-12 pt-2">
            <span
              class="pr-3"
              style="font-weight: 600"
            >Scheduled On: </span>
            <span v-if="liveLectureDetails.created_at">{{
              formattedDateWithTime(liveLectureDetails.created_at)
            }}</span>
          </div>
        </div>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <!-- <el-button @click="dialogVisible = false">Cancel</el-button>
      <el-button type="primary" @click="dialogVisible = false"
        >Confirm</el-button
      > -->
        <div class="col-12 pl-2 mt-1 text-right">
          <button
            type="button"
            class="btn z-depth-0"
            v-ripple="'rgba(255, 255, 255, 0.35)'"
            @click="onFetchAttendance"
            :disabled="
              liveLectureDetails.class_status == 'Missed'
            "
            style="
              padding-left: 20px;
              padding-right: 20px;
              text-transform: capitalize;
              height: 40px;
              border-radius: 4px;
              background-color: var(--el-app-primary);
            "
          >
            <span style="position: relative; bottom: 4px">View Students Attendance
            </span>
          </button>

        </div>
      </span>
    </div>
  </el-dialog>
</template>

<script>
import { format, parseISO } from "date-fns";
import getMonthName from "../../helpers/getMonthName";
export default {
  data() {
    return {
      isBusy: false,
      sessions: [],
    };
  },
  computed: {
    formattedDateWithTime() {
      return (dateString) =>
        format(parseISO(dateString), "do MMMM yyyy ' at ' hh:mma");
    },
    formattedDate() {
      return (dateString) => format(parseISO(dateString), "do MMMM yyyy");
    },
    toMonthName() {
      return (monthNum) => {
        return getMonthName(monthNum);
      };
    },
  },

  watch: {
    liveLectureDetails() {
      if (
        !!this.liveLectureDetails &&
        Object.keys(this.liveLectureDetails).length == 0
      ) {
        this.sessions = [];
      }
    },
  },

  methods: {
    async fetchAttendanceSessions() {
      //Make a request to get Class Attendance Info
      try {
        this.isBusy = true;
        let request = await this.$http.get(
          `live-lecture/user-attendance-sessions/${this.liveLectureDetails.live_lecture_id}/users/${this.liveLectureDetails.lecturer.staff_id}?user_type=Staff`
        );
        if (
          request.data.success &&
          request.data.message ===
            "USER_ATTENDANCE_SESSIONS_FETCHED_SUCCESSFULLY" &&
          request.data.sessions
        ) {
          this.sessions = request.data.sessions;
        } else throw "UNEXPECTED_RESPONSE";
      } catch (error) {
        this.showFailedMessage(
          "Fetching Failed",
          "Unable to fetch Attendance Sessions For the specified Lecture"
        );
      } finally {
        this.isBusy = false;
      }
    },

    async onFetchAttendance() {
      this.isBusy = true;
      try {
        let request = await this.$http.get(
          `live-lecture/fetch-class-report/${this.liveLectureDetails.live_lecture_id}`
        );
        if (
          request.data.success &&
          request.data.message ===
            "STUDENT_CLASS_ATTENDANCE_RETRIEVED_SUCCESSFULLY"
        ) {
          this.$router.push({
            name: "LectureAttendance",
            params: { report: request.data },
          });
        } else throw "UNEXPECTED_RESPONSE";
      } catch (error) {
        this.showFailedMessage(
          "Fetch Failed",
          "Unable to fetch Lecture attendance right now, Please try again"
        );
      } finally {
        this.isBusy = false;
      }
    },
  },
  props: {
    isVisible: {
      required: true,
      type: Boolean,
    },
    beforeClose: {
      required: true,
      type: Function,
    },
    lectureDetailsLoading: {
      required: true,
      type: Boolean,
    },
    liveLectureDetails: {
      required: true,
      type: Object,
    },
  },
};
</script>

<style>
</style>