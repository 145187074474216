import { Notification } from "element-ui";

export const useShowSuccessMessage = (title: string, message: string, shouldPersist = false) => {
    Notification({
        title,
        dangerouslyUseHTMLString: true,
        message : `<span style="color: black; text-align:left">${message}</span>`,
        type: "success",
        duration: shouldPersist ? 0 : undefined,
    });
};

export const useShowWarningMessage = (title: string, message: string) => {
    Notification({
        title,
        dangerouslyUseHTMLString: true,
        message : `<span style="color: black; text-align:left">${message}</span>`,
        type: "warning",
    });
};

export const useShowErrorMessage = (title: string, message: string) => {
    Notification({
        title,
        dangerouslyUseHTMLString: true,
        message : `<span style="color: black; text-align:left">${message}</span>`,
        type: "error",
    });
}
