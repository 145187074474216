<template>
  <section class="container lectures_class">
    <div class="content">
      <div class="row w-100 mb-4">
        <div class="col-12 pl-3 px-0 mb-0 text-left">
          <BreadCrumbComponent :pageTitles="['Lectures']" />
        </div>
      </div>
      <div class="row w-100">
        <div class="col-12 text-left">
          <div
            style="
              font-size: 20px;
              font-weight: 400;
              color: var(--el-app-primary);
              margin-bottom: 15px;
            "
          >
            <strong>Lectures</strong>
          </div>
        </div>
      </div>
      
      <upcoming-lectures-component/>
      <div class="row w-100 mt-3">
        <div class="col-12 text-left" style="font-weight: 600">
          All Lectures History
        </div>
        <div
          class="col-12 text-left mt-4"
          style="font-weight: 400; font-size: 0.9em"
        >
          To view all Lectures including those completed and missed. Press the
          button below
        </div>
        <div class="col-12 pl-2 mt-1 text-left">
          <button
            type="button"
            class="btn z-depth-0"
            @click="$router.push({ path: '/dashboard/lectures/all' })"
            v-ripple="'rgba(255, 255, 255, 0.35)'"
            style="
              width: 140px;
              text-transform: capitalize;
              height: 40px;
              border-radius: 4px;
              background-color: var(--el-app-primary);
            "
          >
            <span style="position: relative; bottom: 4px">View All </span>
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import UpcomingLecturesComponent from "@/components/upcoming-lectures-component.vue";
// import AvailableTestsAndExamsComponent from "../components/available-tests-and-exams-component.vue";
import BreadCrumbComponent from "../components/bread-crumb-component";
export default {
  components: {
    // AvailableTestsAndExamsComponent,
    BreadCrumbComponent,
    UpcomingLecturesComponent,
  },
  data() {
    return {
      tableProps: {
        /*  border: true, */
        stripe: true,
        defaultSort: {
          prop: "title",
          order: "ascending",
        },
      },

      filters: [
        {
          prop: "title",
          value: "",
        },
      ],
    };
  },
};
</script>

<style scoped>
.content {
  margin-top: 45px !important;
}
.ui-margin {
  margin-left: 42px !important;
  margin-right: 42px !important;
}

@media (max-width: 768px) {
  .content {
    margin-top: 15px !important;
  }
}
</style>


<style>
th > .cell {
  color: rgba(0, 0, 0, 0.836) !important;
  font-weight: 600 !important;
  font-size: 13px !important;
}
el-table th > .cell {
  color: black !important;
  font-weight: 500;
}
.el-table .cell {
  font-size: 0.9em;
  line-height: 23px;
  font-weight: 300;
}
/* .lectures_class .el-input input {
  height: calc(1.55rem) !important;
  padding: 0.075rem 0.05rem !important;
  font-size: 0.8rem !important;
  font-weight: 400 !important;
  color: #495057 !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.lectures_class .el-input input:focus {
  color: #495057 !important;
  background-color: #fff !important;
  border-color: #80bdff !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25) !important;
} */
</style>