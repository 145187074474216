/* eslint-disable  no-unused-vars*/
import Vue from "vue";
import store from "../store";

/* eslint-disable no-unused-vars */
import VueRouter, { RouteConfig } from "vue-router";
import Dashboard from "../views/dashboard.vue";
import Home from "../views/home.vue";
// import Chats from "../views/chats.vue";
import MyCalendar from "../views/my-calendar.vue";
import Settings from "../views/settings.vue";
import Help from "../views/help.vue";
import UserProfile from "../views/user-profile.vue";
import StudentProfile from "../views/profiles/student-profile.vue";
import StaffProfile from "../views/profiles/staff-profile.vue";
import SearchResults from "../views/search-results.vue";
import Lectures from "../views/lectures.vue";
import AllLectures from "../views/all-lectures.vue";
import LiveLecture from "../views/live-lecture.vue";
import ResourceDetails from "../views/resource-details.vue";
import MyContent from "../views/my-content.vue";
import MyBatches from "../views/my-batches.vue";
import RecordedLecture from "../views/recorded-lecture.vue";
import LectureAttendance from "../views/lecture-attendance.vue";
import NoticeBoard from "../views/notice-board.vue";
import EnterVerificationCode from "../views/enter-verification-code.vue";
import ResourceContentDetails from "../views/resource-content-details.vue";
import UploadToMultipleCohorts from "../views/upload-to-multiple-cohorts.vue";
import UpdateToMultipleCohorts from "../views/update-to-multiple-cohorts.vue";

import CourseWorks from "../views/courseworks/course-works.vue";
import AddCourseWork from "../views/courseworks/add-course-work.vue";
import CourseWorkDetails from "../views/courseworks/course-work-details.vue";
import AnswerSheet from "../views/courseworks/answer-sheet.vue";
import ExamSubmissions from "../views/courseworks/exam-submissions.vue";
import EditCourseWork from "../views/courseworks/edit-course-work.vue";
import ExamStatistics from "../views/courseworks/exam-statistics.vue";
import Tutorials from "../views/tutorials.vue";
import TutorialDetails from "../views/tutorial-detail.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    /* name: 'Dashboard', 
     component: Dashboard */
    redirect: "/dashboard/home",
    meta: { requiresLogin: true }
  },

  {
    path: "/enter-verification-code",
    name: "EnterVerificationCode",
    component: EnterVerificationCode
  },
  {
    path: "/login",
    name: "Login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/login.vue"),
    meta: { requiresLogin: false }
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "ForgotPassword" */ "../views/forgot-password.vue"
      ),
    meta: { requiresLogin: false }
  },
  {
    path: "/verify-email",
    name: "VerifyEmail",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "VerifyEmail" */ "../views/verify-email.vue"),
    meta: { requiresLogin: false }
  },
  {
    path: "/change-password",
    name: "ChangePassword",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "ChangePassword" */ "../views/change-password.vue"
      ),
    meta: { requiresLogin: false }
  },

  {
    path: '/ongoing-lecture/',
    name: 'OngoingLecture',
    component: () => import(/* webpackChunkName: "OngoingLecture" */ '../views/ongoing-lecture.vue'),
    meta: { requiresLogin: true }
  },

  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: { requiresLogin: true },

    children: [
      {
        path: "home",
        name: "Home",
        component: Home,
        meta: { requiresLogin: true }
      },
      {
        path: "my-content",
        name: "MyContent",
        component: MyContent,
        meta: { requiresLogin: true }
      },
      {
        path: "my-content",
        name: "MyContent",
        component: MyContent,
        meta: { requiresLogin: true }
      },
      {
        path: 'tutorials',
        name: 'Tutorials',
        component: Tutorials,
      },
      {
        path: 'tutorial-details',
        name: 'TutorialDetails',
        component: TutorialDetails,
      },
      {
        path: "upload-to-multiple-cohorts",
        name: "UploadToMultipleCohorts",
        component: UploadToMultipleCohorts,
        meta: { requiresLogin: true }
      },
      {
        path: "update-to-multiple-cohorts/:resource_id",
        name: "UpdateToMultipleCohorts",
        component: UpdateToMultipleCohorts,
        meta: { requiresLogin: true }
      },
      {
        path: "my-batches",
        name: "MyBatches",
        component: MyBatches,
        meta: { requiresLogin: true }
      },
      {
        path: "my-calendar",
        name: "MyCalendar",
        component: MyCalendar,
        meta: { requiresLogin: true }
      },
      {
        path: "settings",
        name: "Settings",
        component: Settings,
        meta: { requiresLogin: true }
      },
      {
        path: "help",
        name: "Help",
        component: Help,
        meta: { requiresLogin: true }
      },
      {
        path: "user-profile",
        name: "UserProfile",
        component: UserProfile
      },
      {
        path: "student-profile/:sId",
        name: "StudentProfile",
        component: StudentProfile
      },
      {
        path: "staff-profile/:sId",
        name: "StaffProfile",
        component: StaffProfile
      },
      {
        path: "search-results",
        name: "SearchResults",
        component: SearchResults
      },
      {
        path: "lectures",
        name: "Lectures",
        component: Lectures,
        meta: { requiresLogin: true }
      },
      {
        path: "live-lecture",
        name: "LiveLecture",
        component: LiveLecture,
        meta: { requiresLogin: true }
      },
      {
        path: "lectures/all",
        name: "AllLectures",
        component: AllLectures,
        meta: { requiresLogin: true }
      },
      {
        path: "resource-details",
        name: "ResourceDetails",
        component: ResourceDetails,
        meta: { requiresLogin: true }
      },
      {
        path: ":rId/resource-content-details",
        name: "ResourceContentDetails",
        component: ResourceContentDetails
      },
      {
        path: "recorded-lecture",
        name: "RecordedLecture",
        component: RecordedLecture
      },
      {
        path: "lecture-attendance",
        name: "LectureAttendance",
        component: LectureAttendance
      },
      {
        path: "notice-board",
        name: "NoticeBoard",
        component: NoticeBoard
      },
      {
        path: "course-work-details/:courseWorkId",
        name: "CourseWorkDetails",
        component: CourseWorkDetails
      },
      {
        path: "course-work/:courseWorkSetId",
        name: "CourseWorks",
        component: CourseWorks
      },
      {
        path: ":courseWorkSetId/course-work/add",
        name: "AddCourseWork",
        component: AddCourseWork
      },
      {
        path: "course-work/edit/:courseWorkId",
        name: "EditCourseWork",
        component: EditCourseWork
      },
      // {
      //   path: ':courseWorkId/course-work-details',
      //   name: 'CourseWorkDetails',
      //   component: CourseWorkDetails,
      // },
      {
        path: ":answerId/answer-sheet",
        name: "AnswerSheet",
        component: AnswerSheet
      },
      {
        path: ":courseWorkId/exam-submissions",
        name: "ExamSubmissions",
        component: ExamSubmissions
      },
      {
        path: "exam-statistics",
        name: "ExamStatistics",
        component: ExamStatistics
      },
      {
        path: "examinations",
        name: "ExaminationSets",
        component: () =>
          import(
            /* webpackChunkName: "examination-sets" */ "../views/examinations/examination-sets.vue"
          ),
      },
      {
        path: "/examinations/:examSetId",
        name: "Examinations",
        component: () =>
          import(
            /* webpackChunkName: "examination-set-details" */ "../views/examinations/examinations.vue"
          ),
      },
      {
        path: "/exams-with-modules-taught-by-lecturer/:examSetId",
        name: "ExamsWithModulesTaughtByLecturer",
        component: () =>
          import(
            /* webpackChunkName: "examinations-taught-by-lecturer" */ "../views/examinations/exams-with-modules-taught-by-lecturer.vue"
          ),
      },
      {
        path: "/exam-course-work-prerequisites/:examId",
        name: "ManageCourseWorkPrerequisites",
        component: () =>
          import(
            /* webpackChunkName: "exam-course-work-prerequisites" */ "../views/examinations/examination-course-work-prerequisites.vue"
          ),
      },
      {
        path: "exam-results-management/:examinationId",
        name: "ExaminationResults",
        component: () =>
          import(
            /* webpackChunkName: "exam-results-management" */ "../views/examinations/exam-results-management.vue"
          ),
      },
      {
        path: "exam-mark-sheets/:examinationId",
        name: "ExaminationResultsSheet",
        component: () =>
          import(
            /* webpackChunkName: "exam-results-management" */ "../views/examinations/exam-mark-sheets.vue"
          ),
      },
      {
        path: "course-work-results-management/:courseWorkId",
        name: "course-work-results-management",
        component: () =>
          import(
            /* webpackChunkName: "course-work-results-management" */ "../components/courseworks/course-work-results-management.vue"
          ),
      },
      {
        path: "course-work-marking/:courseWorkId/:courseWorkSubmissionId",
        name: "course-work-marking",
        component: () =>
          import(
            /* webpackChunkName: "course-work-marking" */ "../views/courseworks/course-work-marking-view.vue"
          ),
      },
      {
        path: "marking/:studentExaminationSubmissionId",
        name: "marking",
        component: () =>
          import(
            /* webpackChunkName: "online-marking" */ "../views/online-marking/online-marking.vue"
          ),
      },
      {
        path: "course-work-sets",
        name: "CourseWorkSets",
        component: () =>
          import(
            /* webpackChunkName: "examination-sets" */ "../views/courseworks/course-work-sets.vue"
          ),
      },
      {
        path: "cafeteria",
        name: "Cafeteria",
        component: () =>
          import(
            /* webpackChunkName: "cafeteria" */ "../views/cafeteria/cafeteria.vue"
          ),
      },
      {
        path: "generate-coupon",
        name: "GenerateCoupon",
        component: () =>
          import(
            /* webpackChunkName: "generate-coupon" */ "../views/cafeteria/generate-coupon.vue"
          ),
      },
      {
        path: "previous-coupons",
        name: "PreviousCoupons",
        component: () =>
          import(
            /* webpackChunkName: "previous-coupons" */ "../views/cafeteria/previous-coupons.vue"
          ),
      },

      //In case the route is anything else
      {
        path: "*",
        redirect: "/dashboard/home"
      }
    ]
  },

  //In case the route is anything else
  {
    path: "*",
    redirect: "/dashboard/home"
  }
  // {
  //    path: '/signup',
  //    name: 'Signup',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  //    component: () => import(/* webpackChunkName: "about" */ '../views/signup.vue')
  //  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0, behavior: "smooth" };
  }
});

router.beforeResolve((to, from, next) => {
  if (
    (to.name === "Login" || to.name === "SignUp") &&
    store.getters.isLoggedIn
  ) {
    next({ path: "/dashboard/home" });
  }
  //Check if the route is a restricted one to some users
  // if (to.name === 'Analysis' || to.name === 'Documentation' || to.name === 'Settings') {
  //   if (store.state.accessLevel != 'Super') {
  //     next({ path: '/pcms' });
  //   }
  // }

  //Check if login is required for Routes with meta data loginRequired!
  if (to.matched.some(record => record.meta.requiresLogin)) {
    if (!store.getters.isLoggedIn) {
      //Redirect to Login Page
      next({ path: "/login" });
    } else next();
  } else next();
});

export default router;
