<template>
  <section v-loading="dataLoading"
    class="container">
    <div class="dashboard_inner_grid">
      <div>
        <div style="margin-bottom: 20px">
          <NoticeBoardDisplayComponent :notices="notices"></NoticeBoardDisplayComponent>
        </div>


        <QuickStatisticsComponent :total_classes_today="total_classes_today"
          :assigned_modules="assigned_modules"
          :pending_resources="pending_resources" />

        <DashboardItemsSummary />
      </div>

      <div class="bg-white"
        style="border-radius: 3px">
        <CalendarComponent v-loading="isMonthCalendarLoading"
          :eventsDays="eventDays"
          :year="calendarYear"
          :month="calendarMonth"
          :dateToday="dateToday"
          @on-change-month="onChangeCalendarMonth"></CalendarComponent>
        <TodaysScheduleComponent :our_upcoming_lectures="upcoming_lectures"></TodaysScheduleComponent>
      </div>
    </div>
  </section>
</template>

<script>
import QuickStatisticsComponent from "../components/quick-statistics-component.vue";
import CalendarComponent from "../components/calendar-component.vue";
import TodaysScheduleComponent from "../components/todays-schedule-component.vue";
import NoticeBoardDisplayComponent from "../components/notice-board-display.vue";
import DashboardItemsSummary from "../components/dashboard-items-summary.vue";

import getMonthName from "../helpers/getMonthName";
import { addMonths } from "date-fns";
// import ScaleOut from "@/components/scale-out-component.vue";

export default {
  components: {
    QuickStatisticsComponent,
    CalendarComponent,
    TodaysScheduleComponent,
    NoticeBoardDisplayComponent,
    DashboardItemsSummary
  },

  data() {
    return {
      dataLoading: false,
      upcoming_lectures: [],
      staff_intake_batches: [],
      notices: [],
      eventDays: [],
      isMonthCalendarLoading: false,
      calendarMonth: 1,
      calendarYear: 2021,
      dateToday: null,
      assigned_modules: "",
      pending_resources: "",
      total_classes_today: "",
    };
  },
  mounted() {
    this.fetchLecturerDashboardHome();
  },
  methods: {
    async onChangeCalendarMonth(diff) {
      try {
        const date = new Date(+this.calendarYear, +this.calendarMonth - 1, 1);
        const newYearAndMonth = addMonths(date, diff);
        const newYear = newYearAndMonth.getFullYear();
        const newMonth = newYearAndMonth.getMonth() + 1;
        const isoMonth = newMonth <= 9 ? `0${newMonth}` : newMonth;
        newYearAndMonth.getMonth() + 1 <= 9
          ? "0" + (newYearAndMonth.getMonth() + 1)
          : newYearAndMonth.getMonth() + 1;
        this.isMonthCalendarLoading = true;
        let request = await this.$http.get(
          `staff/fetch-lecturer-monthly-events?month=${isoMonth}&year=${newYear}`
        );
        if (
          request.data.success &&
          request.data.message == "DAYS_FETCHED_SUCCESSFULLY"
        ) {
          this.eventDays = request.data.month_days;
          this.calendarYear = +newYear;
          this.calendarMonth = +isoMonth;
          // this.dateToday = +today[2];
        } else throw "UNEXPECTED_RESPONSE";
      } catch (error) {
        this.showFailedMessage(
          "Unable to fetch Calendar",
          "Unable to fetch Calendar now, please try again later"
        );
      } finally {
        this.isMonthCalendarLoading = false;
      }
    },
    async fetchLecturerDashboardHome() {
      try {
        this.dataLoading = true;
        let request = await this.$http.get(
          `staff/fetch-dashboard-home/${this.$store.state.userId}`
        );

        if (
          request.data.success &&
          request.data.message == "DASHBOARD_CONTENT_FETCHED_SUCCESSFULLY"
        ) {
          this.dateToday = request.data.date_today;
          const today = request.data.date_today.split("-");
          this.eventDays = request.data.days;
          this.calendarYear = +today[0];
          this.calendarMonth = +today[1];
          this.upcoming_lectures = request.data.upcoming_lectures;
          this.staff_intake_batches = request.data.staff_intake_batches;
          this.notices = request.data.notices;

          this.assigned_modules = request.data.assigned_intakes_modules;
          this.pending_resources = request.data.pending_resources;
          this.total_classes_today = request.data.total_classes_today;

        } else {
          throw "ERROR OCCURED";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        this.showFailedMessage(
          "Unable to load Staff data",
          "An unexpected Error occurred, please try again"
        );
      } finally {
        this.dataLoading = false;
      }
    },

    toMonthName(monthNum) {
      return getMonthName(monthNum, true);
    },
  },
};
</script>

<style scoped>
.in-the-center {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.round-ball-lg {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  background-color: black;
}

.bg-white {
  background-color: white;
}

.bg-blue {
  background-color: var(--el-app-primary);
  color: white;
}

.dashboard_inner_grid {
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 20px;
  margin: 20px 0px;
}

.dashboard_inner_grid>div {
  height: 100%;
}

.grid_row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.grid_row>div {
  height: 100%;
  border-radius: 5px;
  padding: 30px 20px;
}

@keyframes loading-1 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

@keyframes loading-2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(144deg);
    transform: rotate(144deg);
  }
}

@keyframes loading-3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .dashboard_inner_grid {
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 20px;
    margin: 20px 0px;
  }

  .dashboard_inner_grid>div {
    height: 100%;
  }

  .grid_row {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }

  .grid_row>div {
    height: 100%;
    border-radius: 5px;
    padding: 30px 20px;
  }

  .grid_row>div:first-child {
    display: none;
  }
}
</style>

<style>
.el-carousel__arrow {
  background-color: #4b88dd;
}
</style>