/* eslint-disable no-console */
//@ts-nocheck

import { register } from 'register-service-worker'

let appHidden = true;

//Listen for Document Visibility Changes
document.addEventListener('appVisibilityChange', function (event) {
  appHidden = event.detail;
})



// let isPendingUpdate = false;
if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {

    },
    registered() {

    },
    cached() {

    },
    updatefound() {


      document.dispatchEvent(
        new CustomEvent('swUpdateFound', { detail: "" })
      )
    },
    updated(registration) {
      //If App is not visible, show Notification on New Data
      if (appHidden) {
        const notificationOptions = {
          body: "An Update is available for VClass Staff App",
          icon: "https://staff.vclass.ac.ug/img/icons/android-chrome-192x192.png",
        };

        registration.showNotification("New Update Available",
          notificationOptions);
      }


      //If there's an update in the new Service Worker meaning new content is available,
      // var clientIsVisible = false;
      // // eslint-disable-next-line no-undef
      // registration.clients.matchAll({
      //   type: 'window',
      //   includeUncontrolled: true
      // })
      //   .then(function (windowClients) {

      //     for (var i = 0; i < windowClients.length; i++) {
      //       const windowClient = windowClients[i];

      //       if (windowClient.visibilityState === "visible") {
      //         clientIsVisible = true;

      //         break;
      //       }
      //     }

      //     if (!clientIsVisible) {

      //       // const notificationTitle = payload.data.title;
      //       // isPendingUpdate = false;
      //       // eslint-disable-next-line no-unused-vars

      //       const notificationOptions = {
      //         body: "An Update is available forVictoria University",
      //         icon: "https://staff.vclass.ac.ug/img/icons/android-chrome-192x192.png",
      //       };



      //       registration.addEventListener('notificationclick', function (event) {

      //         event.notification.close();

      //         // This looks to see if the current is already open and
      //         // focuses if it is
      //         // eslint-disable-next-line no-undef
      //         event.waitUntil(clients.matchAll({
      //           type: "window"
      //         }).then(function (clientList) {

      //           for (var i = 0; i < clientList.length; i++) {
      //             var client = clientList[i];
      //             if (client.url == '/' && 'focus' in client) {
      //               if (!client.focused)
      //                 return client.focus();

      //             }
      //           }
      //           // eslint-disable-next-line no-undef
      //           if (clients.openWindow)
      //             // eslint-disable-next-line no-undef
      //             return clients.openWindow("https://staff.vclass.ac.ug/");
      //         }));
      //       });


      //       //@ts-ignore
      //       registration.showNotification("New Update Available",
      //         notificationOptions);
      //     }
      //   });


      // //@ts-ignore
      // if (isPendingUpdate && (typeof document.hidden !== "undefined" || typeof document.msHidden !== "undefined" || typeof document.webkitHidden !== "undefined")) {
      // }


      document.dispatchEvent(
        new CustomEvent('swUpdated')
      )
    },
    offline() {

    },
    error(error) { // eslint-disable-line no-unused-vars

    }
  })
}
